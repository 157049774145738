

<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
 
    <div
      class="col-sm-11 movable"
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      <h3 class="head">
        
       Configure New Line
      </h3>
      <div class="successMessage alert-dismissible">
        <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
          {{ warningMessage }}
          <button
            type="button"
            class="btn-close"
            (click)="removeMessage()"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      
    
      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button
          type="button"
          class="btn-close"
          (click)="removeMessage()"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        mat-dialog-close="cancel"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>


</div>
      <div class="container-fluid">
        <div class="row">

    
          <div class="col-sm-6 col-lg-6">
            <div class="drpchildctrl">
          <span class="v1"></span>

          <label class="searchLable" >DOCK</label>
          <p-dropdown


          id="selSupplier"
          [options]="renbanList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          [showTransitionOptions]="'0ms'"
          placeholder="Select"
          optionLabel="container_renban"
          formControlName="masterId"
          [resetFilterOnHide]="true"
          [hideTransitionOptions]="'0ms'"
          autoWidth="true"
          [style]="{ width: '100%' }"
          (onChange)="RenbanList()"
          appendTo="body"
          scrollHeight="180px"

    >
        </p-dropdown>
        <ng-container>
          <small >
          </small>
        </ng-container>
      </div>
      </div>
      <div class="col-sm-6 col-lg-6">
        <div class="drpchildctrl">
          <span class="v1"></span>

          <label class="searchLable">LINE</label>
          <p-dropdown

          id="selSupplier"
          [options]="moduleList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          [showTransitionOptions]="'0ms'"
          placeholder="Select"
          optionLabel="module_number"
          formControlName="masterId"
          [resetFilterOnHide]="true"
          [hideTransitionOptions]="'0ms'"
          autoWidth="true"
          [style]="{ width: '100%' }"
          (onChange)="onModuleChange()"
          appendTo="body"
          scrollHeight="180px"




          >
          </p-dropdown>
          <ng-container>
            <small >
            </small>
          </ng-container>
  
        </div>
      </div>
      <div class="col-sm-6 col-lg-6">
        <div class="drpchildctrl">
            <label class="searchLable">VAN FROM</label>
            <input [(ngModel)]="vanningDT" class="form-control-sm" type="date" autoWidth="false"
                 />
        </div>
    </div>
    <div class="col-sm-6 col-lg-6">
      <div class="drpchildctrl">
            <label class="searchLable">VAN TO</label>
            <input [(ngModel)]="vanningDT1" class="form-control-sm" type="date" autoWidth="false"
                 />
        </div>
    </div>
    <div class="col-sm-6 col-lg-6">
      <div class="drpchildctrl">
          <!-- <label class="searchLable">VAN FROM</label>
          <input [(ngModel)]="vanningDT" class="form-control-sm" type="date" autoWidth="false"
               /> -->
      </div>
  </div>
  <div class="col-sm-6 col-lg-6">
    <div class="drpchildctrl">
          <!-- <label class="searchLable">VAN TO</label>
          <input [(ngModel)]="vanningDT1" class="form-control-sm" type="date" autoWidth="false"
               /> -->
      </div>
  </div>
    <div class="col-sm-6 col-lg-6">
</div>

      <!-- <div class="col-xl-2 col-md-2 col-sm-3 reset_btn">
        <label class="resetIcon" (click)="onResetDropDown()" >
          <img
            src="./assets/images/Group 115380.png"
            alt="refresh icon"
          />
        </label>
        <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
        <span class="vertical"></span>
      </div> -->

      <!-- <div class="col-xl-1 col-md-2 col-sm-3 search">
        <button class="search_btn"  (clic)="onSearch(0)"  [disabled]="disableSearch"
        title="Select at least one Renban and Module 
to perform the search"
        >SEARCH</button>
      </div> -->
        </div>
        <div class="col-sm-6 col-lg-6">
</div>

</div>
<div class="container-fluid">


    <div class="text-right">
      <button class="review_btn" mat-button (click)="saveChanges()"    
      >
        Save
      </button>

      <button class="cancel_btn" mat-button mat-dialog-close="cancel">
        Cancel
      </button>
    </div>
    </div>
