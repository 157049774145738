import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parts-delete-dialog',
  templateUrl: './parts-delete-dialog.component.html',
  styleUrls: ['./parts-delete-dialog.component.scss']
})
export class PartsDeleteDialogComponent implements OnInit {
  business_entity:any;

  constructor() { }

  ngOnInit(): void {
    this.business_entity=localStorage.getItem('namcvalue')
  }
  deleteRecord(){
    return {
      "business_entity":this.business_entity
    }

  }

}
