<div #formTop></div>

<div class="p-2 messageBackground reset_btn">
  <label class="resetIcon" (click)="goBack()">
    <img src="./assets/images/back arrow.png" alt="bell icon" class="bell" />
  </label>

  <label class="message calendarFont" (click)="goBack()"> BACK</label>
</div>
<div class="container-fluid">
  <div class="d-flex flex-row">
    <div class="p-2 flex-grow-1">
      <h2 class="heading">Enter New Air Freight</h2>
    </div>
  </div>
</div>

<div class="successMessage alert-dismissible">
  <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
    {{ warningMessage }}
    <button
      type="button"
      class="btn-close"
      (click)="removeMessage()"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>

  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
    <button
      type="button"
      class="btn-close"
      (click)="removeMessage()"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</div>
<form [formGroup]="addOrEditform">
  <div class="card mb-3">
    <div class="card-body">
      <!-- 1st row -->
      <div class="row justify-content-start">
        <div class="col-3">
          <span class="v1"></span>
          <label class="searchLable">ORDER TYPE</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [options]="toOrder"
            optionLabel="order_type"
            placeholder="Select"
            formControlName="orderType"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
          <ng-container
            *ngIf="addOrEditform.controls.orderType.errors && submitted"
          >
            <small *ngIf="addOrEditform.controls.orderType.errors?.required">
              Part number is required.
            </small>
          </ng-container>
        </div>

        <div class="col-3">
          <span class="v1"></span>
          <label class="searchLable">DEST CODE</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [options]="addAirOrderDestcode"
            optionLabel="dest_code"
            placeholder="Select"
            formControlName="destCode"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
          <ng-container
            *ngIf="addOrEditform.controls.destCode.errors && submitted"
          >
            <small *ngIf="addOrEditform.controls.destCode.errors?.required">
              Part number is required.
            </small>
          </ng-container>
        </div>
      </div>
      <!-- 2nd row -->
      <div class="row">
        <div class="col-3">
          <label required class="searchLable">RESPONSIBILITY</label>
          <label class="radio">
            <input formControlName="responsibility" value="Plant" type="radio"  />
            Plant
          </label>
          <label class="radio">
            <input formControlName="responsibility" value="TMC" type="radio" />
            TMC
          </label>
        </div>
        <div class="col-3">
          <label class="searchLable">RAISED BY</label>
          <input formControlName="raisedBy" class="key" type="text" placeholder="--Type--" />
        </div>

        <div class="col-3">
          <span class="v1"></span>
          <label class="searchLable">REASON</label>

          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            [options]="addAirReason"
            optionLabel="reason_code"
            formControlName="reason"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
          <ng-container
          *ngIf="addOrEditform.controls.reason.errors && submitted"
        >
          <small *ngIf="addOrEditform.controls.reason.errors?.required">
            Reason  is required.
          </small>
        </ng-container>
        </div>

        <div class="col-3">
          <span class="v1"></span>
          <label class="searchLable">ORDER DT</label>
          <input
            class="key"
            type="date"
            formControlName="orderDate"
          />
          <ng-container
            *ngIf="addOrEditform.controls.orderDate.errors && submitted"
          >
            <small *ngIf="addOrEditform.controls.orderDate.errors?.required">
              Order DT is required.
            </small>
          </ng-container>
        </div>
      </div>

      <!-- 3 row -->
      <div class="row">
        <div class="col-md-3">
          <label required class="searchLable">VOLUME</label>
          <label class="radio">
            <input formControlName="volume" value="Additional Parts" type="radio" />
            ADDITIONAL PARTS
          </label>
          <label class="radio">
            <input formControlName="volume" value="Pipeline" type="radio" />
            PIPELINE
          </label>
        </div>

        <div class="col-md-3">
          <label class="searchLable">MANAGER</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
        </div>

        <!-- <div class="row"> -->
        <div class="col-md-3">
          <label class="searchLable">COMMENT 1</label>
          <input type="text" placeholder="--Type--" class="key" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <label class="searchLable">BUDGET CODE</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
        </div>

        <div class="col-md-3">
          <label class="searchLable">VAN CENTER CONTACT</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            [style]="{ width: '100%' }"
            scrollHeight="180px"
          ></p-dropdown>
        </div>
        <div class="col-md-3">
          <label class="searchLable">COMMENT 2</label>
          <input type="text" placeholder="--Type--" class="key" />
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="
            let part of filteredData && filteredData.length
              ? filteredData
              : parts;
            let i = index
          "
          [expanded]="isExpanded[i]"
        >
          <mat-expansion-panel-header (click)="isExpanded[i] = !isExpanded[i]">
            <mat-icon>
              {{ isExpanded[i] ? "expand_more" : "chevron_right" }}
            </mat-icon>
            <span>Part {{ i + 1 }}</span>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-3">
              <label required class="searchLable">PART TYPE</label>
              <label class="radio">
                <input formControlName="partType" value="Production" type="radio" />
                PRODUCTION
              </label>
              <label class="radio">
                <input formControlName="partType" value="Trial" type="radio" />
                TRIAL
              </label>
            </div>

            <div class="col-3">
              <span class="v1"></span>
              <label class="searchLable">KANBAN</label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="30"
                [options]="addAirOrderKanban"
                optionLabel="kanban"
                placeholder="Select"
                formControlName="kanbanNo"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
              ></p-dropdown>
              <ng-container
                *ngIf="addOrEditform.controls.kanbanNo.errors && submitted"
              >
                <small *ngIf="addOrEditform.controls.kanbanNo.errors?.required">
                  Kanban is required.
                </small>
              </ng-container>
            </div>

            <div class="col-3">
              <span class="v1"></span>
              <label class="searchLable">PART NUMBER</label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                
                [itemSize]="30"
                [options]="addAirOrderPart"
                optionLabel="item_id"
                placeholder="Select"
                formControlName="partNo"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
               
              ></p-dropdown>
              <ng-container
                *ngIf="addOrEditform.controls.partNo.errors && submitted"
              >
                <small *ngIf="addOrEditform.controls.partNo.errors?.required">
                  Part number is required.
                </small>
              </ng-container>
            </div>

            <div class="col-3">
              <span class="v1"></span>
              <label class="searchLable">DOCK </label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="30"
                [options]="addAirOrderDock"
                optionLabel="dock"
                placeholder="Select"
                formControlName="dockNo"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
              ></p-dropdown>
              <ng-container
                *ngIf="addOrEditform.controls.dockNo.errors && submitted"
              >
                <small *ngIf="addOrEditform.controls.dockNo.errors?.required">
                  Part number is required.
                </small>
              </ng-container>
            </div>

            <div class="col-3">
              <span class="v1"></span>
              <label class="searchLable">AIR CASE CODE </label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="30"
                [options]="addAirOrderPart"
                optionLabel="item_id"
                placeholder="Select"
                formControlName="partNo"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
              ></p-dropdown>
              <!-- <ng-container
                *ngIf="addOrEditform.controls.partNo.errors && submitted"
              >
                <small *ngIf="addOrEditform.controls.partNo.errors?.required">
                  Part number is required.
                </small>
              </ng-container> -->
            </div>

            <div class="col-3" *ngIf="!isLotsSelected">
              <span class="v1"></span>
              <label class="searchLable">NO OF PIECES TO ORDER</label>
              <input
                class="key"
                type="text"
                placeholder="--Type--"
                formControlName="piecesToOrder"
                (keydown)="allowOnlyNumbers($event)"
              />
            </div>
            <div class="col-3" *ngIf="!isLotsSelected">
              <span class="v1"></span>
              <label class="searchLable">QPC</label>
              <input
                class="key"
                type="text"
                placeholder="--Type--"
                formControlName="qpc"
                (keydown)="allowOnlyNumbers($event)"
              />
              <ng-container
                *ngIf="addOrEditform.controls.qpc.errors && submitted"
              >
                <small *ngIf="addOrEditform.controls.qpc.errors?.required">
                  QPC is required.
                </small>
              </ng-container>
            </div>

            <div class="col-3" *ngIf="isLotsSelected">
              <label class="searchLable"># OF LOTS TO ORDER</label>
              <input class="key" type="text" placeholder="--Type--" required />
            </div>

            <div class="col-3">
              <label class="searchLable">DESCRIPTION</label>
              <input class="key" type="text" placeholder="--Type--" />
            </div>

            <div class="col-3">
              <label class="searchLable">ORDER LOT</label>
              <input class="key" type="text" placeholder="--Type--" />
            </div>

            <div class="col-3">
              <label class="searchLable">TOTAL ORDER</label>
              <input
                class="key"
                type="text"
                placeholder="--Type--"
                formControlName="totalOrder"
                (keydown)="handleKeydown($event)"
              />
            </div>

            <div class="col-3">
              <label class="searchLable">BOX WEIGHT(KG)</label>
              <input
                class="key"
                type="text"
                placeholder="--Type--"
                formControlName="boxWeight"
                (keydown)="handleKeydown($event)"
              />
            </div>

            <div class="col-3">
              <label required class="searchLable">COST CALCULATION</label>
              <label class="radio">
                <input formControlName="costCalculation" value="Weight" type="radio" />
                WEIGHT
              </label>
              <label class="radio">
                <input formControlName="costCalculation" value="Volume" type="radio" />
                VOLUME
              </label>
            </div>

            <div class="col-3">
              <label class="searchLable">ESTIMATED COST USD:</label>
              <input
                class="key"
                type="text"
                placeholder="--Type--"
                formControlName="estimatedCost"
                (keydown)="handleKeydown($event)"
              />
            </div>

            <div class="col-3">
              <label class="searchLable">KVC CONTACT</label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="30"
                placeholder="Select"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
              ></p-dropdown>
            </div>

            <div class="col-3">
              <label class="searchLable">COORDINATOR</label>
              <p-dropdown
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="30"
                placeholder="Select"
                [style]="{ width: '100%' }"
                scrollHeight="180px"
              ></p-dropdown>
            </div>

            <div class="col-3">
              <label required class="searchLable">VAN CENTER CONFIRMATION</label>
              
              <label class="radio">
                <input formControlName="vanConfirmation" type="radio" value="Yes" /> Yes
              </label>
              
              <label class="radio">
                <input formControlName="vanConfirmation" type="radio" value="No" /> No
              </label>
            </div>
          </div>

          <div class="row">
            <label class="resetlabel1">
              <p (click)="addRow()">Add Arrival Date</p>
            </label>
          </div>

          <div class="row">
            <app-grid
              class="table-outer"
              #osPartsGrid
              [columnDefs]="columnDefs"
              [rowData]="rowData"
              [gridOptions]="gridOptions"
              [frameworkComponents]="frameworkComponents"
              [floatingFilter]="true"
              [context]="context"
            ></app-grid>
          </div>

          <div class="mt-3" *ngIf="isExpanded[i]">
            <button class="review_btn" (click)="addPart()">Add Part</button> &nbsp;&nbsp;
            <button class="cancel_btn1" (click)="saveOrder(i)">Save</button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</form>
<ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>