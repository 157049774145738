import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-cancel-activate-air-order',
  templateUrl: './cancel-activate-air-order.component.html',
  styleUrls: ['./cancel-activate-air-order.component.scss']
})
export class CancelActivateAirOrderComponent implements OnInit {
  business_entity:any;
  showCanceledPopUP:any=false

  constructor( @Inject(MAT_DIALOG_DATA) public data: any = {},
  public dialogRef: MatDialogRef<CancelActivateAirOrderComponent>)  { }

  ngOnInit(): void {
    this.business_entity=localStorage.getItem('namcvalue')
    if(this.data=='negative'){
      this.showCanceledPopUP=true

    }
  }

  onActivate(): void {
    this.dialogRef.close('activate');  // Ensure this is called when the Activate button is clicked
  }
  

}
