import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import { OSPStateService } from '../services/osp-state.service';
import { PartstmaintenanceService } from '../services/orderforecast/parts-maintenance/partsmaintenance.service';

import { RowSelectedEvent } from 'ag-grid-community';
import * as mm from 'moment-timezone';
import { PartstatusService } from '../services/part-status/partstatus.service';

import { forkJoin, Subscription } from 'rxjs';
import { checkAccessPipe, dateFilterComparator, resetSortingAndFilters, warningMessage } from '../constants';

import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { logData, logErrors } from '../shared/logger';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { Router } from '@angular/router';
import { log } from 'console';
import { DeleteLinesComponent } from './delete-lines.component';
import { deletelineDialogComponent } from './line-dialog/linedelete-dialog.component';
import { ConfigurelinesComponent } from './dialog-configureline/configureline.component';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration-lines.component.html',
    styleUrls: ['./configuration-lines.component.scss'],
})
export class ConfigurationlinesComponent implements OnInit, OnDestroy {
    showDownloadUploadButtons: boolean = true;

    namcValue = localStorage.getItem('namcvalue');
    @ViewChild('osPartsGrid') osPartsGrid;

    count = 0;
    rowData = [];
    rowData2;
    columnDefsMaster;
    columnDefs;
    groupRowsSticky;
    user = '';
    userRole = '';
    rowGroupingDisplayType = 'custom';
    groupDefaultExpanded = 1;
    suppressGroupRowsSticky: boolean = true;
    partsinquireSubscription$: Subscription;
    disablePartNumber: boolean;
    disableKanban: boolean;
    vanningDT: any;
    vanningDT2: any;

    inventoryRes: any = [];

    animateRows = true;
    showOpenedGroup = false;

    public defaultColDef;
    rowSelection;
    rowGroupPanelShow;
    groupSelectsChildren;
    suppressRowClickSelection;
    suppressAggFuncInHeader;
    public isChecked = false;

    groupDisplayType = 'groupRows';
    autoGroupColumnDef;
    getDataPath;

    msgNoContent = 'No Content';
    gridOptions;
    paginationPageSize = 0;
    paginationNumberFormatter;
    pagination = 'true';
    context: any;
    editType;
    suppressClickEdit = true;
    tooltipShowDelay = 0;
    frameworkComponents = {
        customTooltip: CustomTooltipComponent,
        buttonRenderer: DeleteLinesComponent,
    };

    editEnabled: boolean = false;
    freezeStatus: boolean = false;
    showToggleAll: boolean = false;
    showClear: boolean = true;

    // Dropdowns
    selectedKanban: any = [];
    selectedPartNo: any = [];
    selectedrenban: any = [];
    seletedModuleCode: any = [];
    seletedModule: any = [];
    seletedStatus: any = [];
    selectedContainer: any = [];
    successMessage = '';
    warningMessage = '';
    vanningFrom: any;
    vanningTo: any;

    kanbanListSelected = [];
    // Dropdown Options
    kanbanList: any = [];
    partNoList: any = [];
    renbanList: any = [];
    moduleCodeList: any = [];
    moduleList: any = [];
    statusList: any = [];
    containerList: any = [];
    rowCount: number = 0;
    isEditMode: boolean = false;
    dataCount: number = 0;
    offset: number = 0;
    renbanFrom: any;
    renbanTo: any;

    showHideList: any = [
        {
            label: 'Dock',
            value: 'dock',
        },
        {
            label: 'lines',
            value: 'lines',
        },
        {
            label: 'Valid From',
            value: 'van_from',
        },
        {
            label: 'Valid To',
            value: 'van_to',
        },


    ];

    selectedShowHideList: any = [...this.showHideList];

    kanbanSelected = 1;

    page = 0;
    // apiDefaultLimit = 8000;
    commentsList: any = [];

    uploadError: boolean;
    uploadEnabled: boolean;
    namcName: string;
    files: any[];
    fileBuffer: any;

    exportData: any;

    uploading: boolean = false;

    timesEditApiCalled: number = 0;
    editApiStartPosition: number = 0;
    batchRunLogID: any;
    headerHeight: number;
    loading: any;

    headerCheckboxChecked: boolean = false;
    allowedFilesize: number;
    onInitVanningFrom: any;
    onInitVanningTo: any;
    justFileName: any;
    validating: boolean;
    itContact: string;
    easternDate: any;
    defaultPageSize: any = 100000;
    kanbanSearchValue: any;
    renbanSearchValue: any;
    renbanRangeSearchValue: any;
    renbanFromSearchValue: any;
    renbanValue: any;

    constructor(
        private readonly spinner: NgxSpinnerService,
        private readonly excelService: ExcelService,
        private readonly gridDataService: GriddataService,
        private readonly pipeLookupService: PipeLookupService,
        private readonly partstatusService: PartstatusService,
        private readonly stateService: OSPStateService,
        private readonly router: Router,
        public dialog: MatDialog,
        private readonly partstmaintenanceService: PartstmaintenanceService
    ) {
        this.context = {
            componentParent: this,
        };
    }

    fileName: string = '';
    access1;
    // DATE COMPARATOR FOR SORTING
    dateComparator(date1, date2) {
        function monthToNum(date) {
            if (date === undefined || date === null) {
                return null;
            }

            const newDate: Date = new Date(date);

            const yearNumber = newDate.getFullYear();
            const monthNumber = newDate.getMonth();
            const dayNumber = newDate.getDate();
            const hoursNumber = newDate.getHours();
            const minutesNumber = newDate.getMinutes();

            const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
            return result;
        }

        // To be used inside filter params when using date filter

        const date1Number = monthToNum(date1);
        const date2Number = monthToNum(date2);

        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }

        return date1Number - date2Number;
    }
    onPageSizeChanged() {
        if (this.gridOptions?.api) {
            this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
        }
    }
    setPageSizeToAll(pageSize?) {
        this.paginationPageSize = this.rowData.length;

        if (pageSize) {
            this.paginationPageSize = 0;
            this.rowData.length = 0;
        }

        this.onPageSizeChanged();
    }

    ngOnDestroy(): void {
        this.partsinquireSubscription$.unsubscribe();
    }
    ngOnInit(): void {
        const access = checkAccessPipe();
        this.access1 = access.access;
        
        // normal grid code added
        this.columnDefsMaster = [
            {
                headerName: '',
                field: 'rowCheckBox',
                width: 44,
                headerComponentParams: {
                    headerChecked: this.headerCheckboxChecked,
                },
                headerComponentFramework: HeaderCheckboxComponent,
                floatingFilterComponentFramework: GridRefreshComponent,
                floatingFilterComponentParams: { suppressFilterButton: true },

                cellRendererFramework: RowCheckboxComponent,
                floatingFilter: true,
                pinned: 'left',
            },
            {
                headerName: 'Dock',
                headerTooltip: 'Dock',
                field: 'dock',
                sortable: true,
                width: 250,

                floatingFilter: true,
            },
            {
                headerName: 'Lines',
                headerTooltip: 'lines',
                field: 'lines',
                sortable: true,
                width: 250,
                floatingFilter: true,
            },
            {
                headerName: 'Valid From',
                field: 'van_from',
                headerTooltip: 'Vanning  From Date(mm/dd/yyyy)',
                sortable: true,
                width: 200,
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateFilterComparator,
                },
                floatingFilter: true,
                comparator: this.dateComparator,
            },
            {
                headerName: 'Valid To',
                headerTooltip: 'Vanning To Date(mm/dd/yyyy)',
                field: 'van_to',
                sortable: true,
                width: 200,
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateFilterComparator,
                },
                floatingFilter: true,
                comparator: this.dateComparator,
            },
            {
                headerName: 'Action',
                cellRendererFramework: DeleteLinesComponent,
                field: 'actions',
                width: 500,
                sortable: false,
                floatingFilter: false,
            },
        ];
        this.headerHeight = 45;

        // this.setPageSizeToAll();
        this.rowSelection = 'multiple';
        this.editType = 'fullRow';
        this.rowSelection = 'multiple';
        this.editType = 'fullRow';
        this.gridOptions = this.gridDataService.getGridOptions();
        this.gridOptions.onGridReady = function (params) {
            this.gridApi = params.api;
        };
        this.columnDefs = [...this.columnDefsMaster];
        this.rowData = [{
            dock: "BW", lines: "30", van_from:'',
            van_to:''
        },
        {
            dock: "EA", lines: "30",
            van_from:'',
            van_to:''
        },
        {
            dock: "R1", lines: "32",
            van_from:'',
            van_to:''
        },
        {
            dock: "R1", lines: "30",
            van_from:'',
            van_to:''
        },
        {
            dock: "R1", lines: "33"
        }]
        this.partsinquireSubscription$ = this.stateService.getNamc().subscribe((observable) => {
            this.initializeData();

        });



        
        
    }

    initializeData() {
        // this.spinner.show();
        // this.rowData = [];
        // this.spinner.show();

        this.namcValue = localStorage.getItem('namcvalue');

        if (localStorage.getItem('workdayId')) {
            this.user = localStorage.getItem('workdayId');
        }
        if (localStorage.getItem('UserRoles')) {
            this.userRole = localStorage.getItem('UserRoles');
        }

        this.loadDrop();
        // this.onResetDropDown();
    }
    checkIfAnySelected() {
        if (
            this.selectedKanban.length > 0 ||
            this.selectedPartNo.length > 0 ||
            this.seletedModule.length > 0 ||
            this.selectedContainer.length > 0 ||
            this.seletedModuleCode.length > 0 ||
            this.seletedStatus.length > 0 ||
            this.selectedrenban.length > 0 ||
            this.vanningDT
        ) {
            return true;
        }

        return false;
    }




    checkIfExist(inputValues) {
        // Check if any input value exists in the data array (case-insensitive)
        const uppercaseInputValues = inputValues.map((value) => value.toUpperCase());

        // Check if all input values exist in the data array (case-insensitive)
        return uppercaseInputValues.every((input) =>
            this.renbanList.some((item) => item.container_renban.toUpperCase() === input)
        );
    }


    deleteline(data) {

        if (this.isEditMode) {
            return;
        }

        var undevanData = data;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-component';
        dialogConfig.height = '204px';
        dialogConfig.width = '450px';
        dialogConfig.data = undevanData;
        const dialogRef = this.dialog.open(deletelineDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((res) => {
            if (res === 'cancel') {
                return;
            } else if (res === 'save') {
                this.successMessage = 'line deleted successfully';
                this.rowData = [{
                    dock: "BW", lines: "30", van_from:'',
                    van_to:''
                },
                {
                    dock: "EA", lines: "30",
                    van_from:'',
                    van_to:''
                },
                {
                    dock: "R1", lines: "32",
                    van_from:'',
                    van_to:''
                },
                {
                    dock: "R1", lines: "33",
                    van_from:'',
                    van_to:''
                },
                {
                    dock: "R1", lines: "30"
                }]
                this.loadDrop();
                
            }
        });
    }


    configureLine() {
        this.onResetDropDown();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '550px';
        dialogConfig.height = '250px';
        var data = {
            modifiedRowData: this.getModifiedRowData(),
        };

        dialogConfig.data = data;
        const dialogRef = this.dialog.open(ConfigurelinesComponent, dialogConfig);

        const addGetResponse = dialogRef.componentInstance.editEventEmitter.subscribe((res) => {


            if (res === 'cancel') {
                // this.osPartsGrid.gridOptions.api.refreshHeader();
            } else {
                console.log("response", res);
                let data = {
                    modifiedRowData: res,
                };


                console.log("else", data);
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.id = 'modal1-component';
                dialogConfig.height = '204px';
                dialogConfig.width = '440px';
                dialogConfig.data = data;


                const dialogRef = this.dialog.closeAll();
                this.spinner.hide();
                this.kanbanList = [];
                this.partNoList = [];
            this.partNoList.push({ part_number: 'ALL' },
            { part_number: 'BW' },
            { part_number: 'EA' },
            { part_number: 'R1' });
            this.kanbanList.push({ kanban: 'ALL' },
            { kanban: '30' },
            { kanban: '31' },
            { kanban: '31' });
            this.rowData = [{
                dock: "BW", lines: "30", van_from:'',
                van_to:''
            },
            {
                dock: "EA", lines: "30",
                van_from:'',
                van_to:''
            },
            {
                dock: "R1", lines: "32",
                van_from:'',
                van_to:''
            },
            {
                dock: "R1", lines: "30",
                van_from:'',
                van_to:''
            },
            {
                dock: "R1", lines: "33"
            }]
            this.setPageSizeToAll();
                    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
    
    
    
    
                this.successMessage = 'line configured for the dock'


                // dialogRef.afterClosed().subscribe((res) => {
                //   if (res === 'cancel') {
                //     return;

                //   }
                //   else if (res === 'save') {

                //     console.log("inside save", data.modifiedRowData);



                //     this.successMessage='line configured for the dock'

                //     this.dialog.closeAll()


                //   }


                // });






            }

        });




    }



    resetGrid() {
        if (this.osPartsGrid) {
            resetSortingAndFilters(this.osPartsGrid);

            return;
        }
    }

    onSearch(offset) {
        // if (this.osPartsGrid) {
        //     this.headerCheckboxChecked = false;
        //     this.osPartsGrid.gridOptions.api.refreshHeader();

        //     this.resetGrid();
        // }

        // this.removeMessage();
        // if (this.renbanSearchValue && this.kanbanSearchValue && this.renbanFromSearchValue && this.renbanRangeSearchValue) {
        //     this.kanbanSearchValue = null;
        //     this.renbanSearchValue = null;
        //     this.renbanFromSearchValue = null;
        //     this.renbanFromSearchValue = null;
        //     this.renbanValue = null;
        //     this.renbanFrom = null;
        //     this.renbanTo = null;

        //     return;
        // }
        // if (this.renbanSearchValue) {
        //     this.kanbanSearchValue = null;
        //     this.renbanSearchValue = this.renbanSearchValue.trim();
        //     const value = this.renbanSearchValue;

        //     this.rowData = [];

        //     const searchedRenban = this.renbanList.find(
        //         (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
        //     );

        //     if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
        //         this.onResetDropDown();
        //         this.warningMessage = warningMessage.invalidRenbanSearch;
        //         return;
        //     }

        //     this.selectedKanban = [];
        //     this.selectedPartNo = [];
        //     this.selectedrenban = [];
        //     this.seletedModuleCode = [];
        //     this.seletedModule = [];
        //     this.seletedStatus = [];

        //     this.selectedContainer = [];
        //     this.selectedrenban = [searchedRenban];

        //     // this.onSearch(0);
        // }
        // if (this.kanbanSearchValue) {
        //     this.renbanSearchValue = null;
        //     this.renbanValue = null;
        //     this.renbanFrom = null;
        //     this.renbanTo = null;

        //     this.kanbanSearchValue = this.kanbanSearchValue.trim();
        //     const value = this.kanbanSearchValue;

        //     this.rowData = [];

        //     const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban?.toLowerCase() === value.toLowerCase());

        //     if (!searchedKanban || searchedKanban.kanban === 'ALL') {
        //         this.onResetDropDown();
        //         this.warningMessage = warningMessage.invalidKanbanSearch;
        //         return;
        //     }

        //     this.selectedKanban = [];
        //     this.selectedPartNo = [];
        //     this.selectedrenban = [];
        //     this.seletedModuleCode = [];
        //     this.seletedModule = [];
        //     this.seletedStatus = [];

        //     this.selectedContainer = [];
        //     this.selectedKanban = [searchedKanban];

        //     // this.onSearch(0);
        // }

        // if (offset === 0) {
        //     this.setPageSizeToAll(this.defaultPageSize);
        //     this.rowData = [];
        //     this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

        //     this.offset = 0;
        //     this.spinner.show();
        // }

        // if (!this.checkIfAnySelected()) {
        //     this.rowData = [];
        //     this.warningMessage = warningMessage.selectToSearch;

        //     window.scroll(0, 0);
        //     this.spinner.hide();
        //     return false;
        // }
        // let kanban;
        // let selectedPartNo;
        // let selectedrenban;
        // let selectedContainer;
        // let seletedModuleCode;
        // let seletedModule;
        // let seletedStatus;

        // if (this.selectedKanban.length > 0) {
        //     if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        //         kanban = [];
        //         this.selectedKanban.forEach((element) => {
        //             kanban.push(element.kanban);
        //         });
        //     }
        // }

        // if (this.selectedPartNo.length > 0) {
        //     if (!this.selectedPartNo.some((a) => a.part_number === 'ALL')) {
        //         selectedPartNo = [];
        //         this.selectedPartNo.forEach((element) => {
        //             selectedPartNo.push(element.part_number);
        //         });
        //     }
        // }

        // if (this.selectedrenban.length > 0) {
        //     if (!this.selectedrenban.some((a) => a.container_renban === 'ALL')) {
        //         selectedrenban = [];
        //         this.selectedrenban.forEach((element) => {
        //             selectedrenban.push(element.container_renban);
        //         });
        //     }
        // }

        // if (this.selectedContainer.length > 0) {
        //     if (!this.selectedContainer.some((a) => a.cc === 'ALL')) {
        //         selectedContainer = [];
        //         this.selectedContainer.forEach((element) => {
        //             selectedContainer.push(element.cc);
        //         });
        //     }
        // }

        // if (this.seletedModuleCode.length > 0) {
        //     if (!this.seletedModuleCode.some((a) => a.modulecode === 'ALL')) {
        //         seletedModuleCode = [];
        //         this.seletedModuleCode.forEach((element) => {
        //             seletedModuleCode.push(element.modulecode);
        //         });
        //     }
        // }

        // if (this.seletedModule.length > 0) {
        //     if (!this.seletedModule.some((a) => a.module_number === 'ALL')) {
        //         seletedModule = [];
        //         this.seletedModule.forEach((element) => {
        //             seletedModule.push(element.module_number);
        //         });
        //     }
        // }

        // if (this.seletedStatus.length > 0) {
        //     if (!this.seletedStatus.some((a) => a.container_status === 'ALL')) {
        //         seletedStatus = [];
        //         this.seletedStatus.forEach((element) => {
        //             seletedStatus.push(element.container_status);
        //         });
        //     }
        // }

        // const data = {
        //     offset: offset,
        //     limit: 6500,
        //     business_entity: this.namcValue,
        //     workid: this.user,
        //     user_role: this.userRole,
        //     kanban: kanban,
        //     part_number: selectedPartNo,
        //     renban: selectedrenban,
        //     cc: selectedContainer,
        //     module: seletedModule,
        //     module_code: seletedModuleCode,
        //     status: seletedStatus,
        //     renbanFrom: this.renbanFrom,
        //     renbanTo: this.renbanTo,
        //     tmc_van_completion_date: this.vanningDT,
        // };
        // this.partstatusService.getInquiryData(data).subscribe({
        //     error: this.errorCallback,
        //     next: (res) => {
        //         if (res.body && res.body.pipeinquiryResponse) {
        //             this.rowData.push(...res.body.pipeinquiryResponse.data);
        //             this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
        //             this.dataCount = parseInt(res.body.pipeinquiryResponse.rowCount);
        //             const records = this.dataCount - (this.offset + 1) * 6500;
        //             if (records >= 1) {
        //                 this.offset++;
        //                 this.onSearch(this.offset * 6500);
        //             } else {
        //                 this.spinner.hide();
        //             }
        //         } else {
        //             this.rowData = [];
        //             this.spinner.hide();
        //         }

        //         this.setPageSizeToAll();
        //     },
        // });
        return true;
    }

    loadDrop() {
        this.spinner.show();

        const data = {
            business_entity: this.namcValue,
            workid: this.user,
            user_role: this.userRole,
        };
        const dataShowHide = {
            business_entity: this.namcValue,
            business_entity_name: this.namcName,
            workid: this.user,
            user_role: this.userRole,
        };

        // const lookupSubscriptions = [
        //   this.pipeLookupService.getModuleCodeList(data),
        //   this.pipeLookupService.getKanbanList(data),
        //   this.pipeLookupService.getPartNoList(data),

        //   this.pipeLookupService.getStatuList(data),
        //   this.pipeLookupService.getCcList(data),
        //   this.pipeLookupService.getRenbanList(data),
        //   this.pipeLookupService.getModuleList(data),
        //   this.pipeLookupService.getUserShowHideColumns(dataShowHide),
        // ];
        // forkJoin(lookupSubscriptions).subscribe({
        //   next: (res) => {
        //     //if kanbanList, add ALL option
        //     this.kanbanList = [];
        //     this.partNoList = [];
        //     this.moduleCodeList = [];
        //     this.moduleList = [];
        //     this.statusList = [];
        //     this.renbanList = [];
        //     this.containerList = [];

        //     if (res[1].body.data) {
        //       this.kanbanList.push({ kanban: 'ALL' });
        //     }

        //     this.kanbanList.push(...res[1].body.data);

        //     // if item id list, add ALL option
        //     if (res[2].body.data) {
        //       this.partNoList.push({ part_number: 'ALL' });
        //     }

        //     this.partNoList.push(...res[2].body.data);

        //     if (res[3].body.data) {
        //       this.statusList.push({ container_status: 'ALL' });
        //     }

        //     this.statusList.push(...res[3].body.data);

        //     if (res[0].body.data) {
        //       this.moduleCodeList.push({ modulecode: 'ALL' });
        //     }
        //     this.moduleCodeList.push(...res[0].body.data);

        //     if (res[5].body.data) {
        //       this.renbanList.push({ container_renban: 'ALL' });
        //     }
        //     this.renbanList.push(...res[5].body.data);

        //     if (res[6].body.data) {
        //       this.moduleList.push({ module_number: 'ALL' });
        //     }
        //     this.moduleList.push(...res[6].body.data);
        //     if (res[4].body.data) {
        //       this.containerList.push({ cc: 'ALL' });
        //     }
        //     this.containerList.push(...res[4].body.data);
        //     this.containerList = this.containerList.filter(
        //       (value, index, self) => index === self.findIndex((t) => t.cc === value.cc)
        //     );

        //     // batch status check
        //     const { parts_inquire_columns } = res[7].body.showHideColumnsResponse;

        //     try {
        //       const hideItems = JSON.parse(parts_inquire_columns);

        //       if (typeof hideItems === 'object' && hideItems.length > 0) {
        //         this.selectedShowHideList = this.showHideList.filter(
        //           (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
        //         );

        //         logData(hideItems);

        //         this.onShowHideChange({
        //           value: this.selectedShowHideList,
        //           onInit: true,
        //         });
        //       }
        //     } catch (error) {}

        //     this.spinner.hide();
        //   },
        //   error: this.errorCallback,
        // });
        this.spinner.hide();
        this.kanbanList = [];
        this.partNoList = [];
    this.partNoList.push({ part_number: 'ALL' },
    { part_number: 'BW' },
    { part_number: 'EA' },
    { part_number: 'R1' });
    this.kanbanList.push({ kanban: 'ALL' },
    { kanban: '30' },
    { kanban: '31' },
    { kanban: '31' });

        this.setPageSizeToAll();
        this.osPartsGrid.gridOptions.api.setRowData(this.rowData);



        this.spinner.hide();



        
        console.log("rowdata", this.rowData)
    }


    errorCallback = (error) => {
        logErrors(error);
        this.spinner.hide();

        this.warningMessage = 'Something went wrong! Please contact support team.';
        this.rowData = [];
        this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
    };

    onResetDropDown() {
        this.removeMessage();
        this.selectedKanban = [];
        this.selectedPartNo = [];
        this.selectedrenban = [];
        this.seletedModuleCode = [];
        this.seletedModule = [];
        this.seletedStatus = [];

        this.selectedContainer = [];
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.vanningDT = null;

        this.kanbanSearchValue = null;
        this.renbanFromSearchValue = null;
        this.renbanRangeSearchValue = null;

        if (this.osPartsGrid) {
            this.headerCheckboxChecked = false;
            this.osPartsGrid.gridOptions.api.refreshHeader();
            this.resetGrid();
        }

        this.setPageSizeToAll(this.defaultPageSize);

        this.rowData = [];
        this.disablePartNumber = false;

        this.disableKanban = false;
        this.offset = 0;
    }

    toggleHeaderCheckbox(checked) {
        if (!checked) {
            this.headerCheckboxChecked = false;

            this.osPartsGrid.gridOptions.api.refreshHeader();

            return;
        }

        const rowCount = this.osPartsGrid.gridOptions.rowData.length;
        const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

        if (rowCount === selectedRowCount) {
            this.headerCheckboxChecked = true;

            this.osPartsGrid.gridOptions.api.refreshHeader();
        }
    }
    userCheckChanged(checked, rowIndex) {
        const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

        if (checked === true) {
            rowNode.setSelected(true);
            rowNode.data.rowCheckBox = true;
        } else {
            rowNode.setSelected(false);
            rowNode.data.checkedEditMode = false;
            rowNode.data.rowCheckBox = false;
        }

        this.gridOptions.getRowStyle = function (params) {
            if (params.node.rowIndex === rowIndex) {
                const color = checked ? '#E4ECF0' : 'white';
                return { background: color };
            }
            return { background: 'white' };
        };

        rowNode.setData(rowNode.data);
        this.toggleHeaderCheckbox(checked);
        this.spinner.hide();
    }

    headerCheckChanged(event) {
        console.log('headercheckchanged', event);

        this.headerCheckboxChecked = event.checked;
        const headerChecked = event.checked;

        this.osPartsGrid.gridOptions.api.forEachLeafNode(function (rowNode, index) {
            if (headerChecked) {
                rowNode.data.rowCheckBox = true;
                rowNode.setSelected(true);
            } else {
                rowNode.setSelected(false);
                rowNode.data.rowCheckBox = false;
            }
            rowNode.setData(rowNode.data);
        });
    }

    onRowSelected(event: RowSelectedEvent) {
        const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(event.rowIndex);
        const rowNodeIndex = event.rowIndex;
        const rowData = this.osPartsGrid.gridOptions.api.getSelectedRows();
        // console.log("api----", event.node.isSelected());
        // console.log("rowData", rowData);

        // if(event.node.isSelected())
        // {
        //   // rowNode.setSelected(true);
        //   rowData.rowCheckBox = true;
        // }
        // else{
        //   // rowNode.setSelected(false);
        //   rowData.rowCheckBox = false;
        // }

        this.gridOptions.getRowStyle = function (params) {
            if (params.node.rowIndex === rowNodeIndex) {
                const color = event.node.isSelected() ? '#E4ECF0' : 'white';
                return { background: color };
            }
            return { background: 'white' };
        };

        rowNode.setData(rowNode.data);
        this.toggleHeaderCheckbox(event.node.isSelected());
        this.spinner.hide();
    }

    parts_inquire_columns;

    saveShowHide() {
        this.spinner.show();

        let parts_inquire_columns = this.parts_inquire_columns;
        const data = {
            business_entity: this.namcValue,
            business_entity_name: this.namcName,
            workid: this.user,
            user_role: this.userRole,
        };

        if (parts_inquire_columns) {
            this.pipeLookupService.updateUserShowHideColumns(data, { parts_inquire_columns }).subscribe({
                error: this.errorCallback,
                next: (res) => {
                    this.spinner.hide();
                },
            });
        } else {
            this.spinner.hide();
        }
    }
    onShowHideChange(ev: any) {
        this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

        const hideItems = this.showHideList.filter((item) => {
            return !ev.value.map((sItem) => sItem.value).includes(item.value);
        });

        this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

        if (ev.onInit) return;

        this.parts_inquire_columns = hideItems;
        if (!hideItems.length) this.parts_inquire_columns = [{}];
    }
    onKanbanChange(): void {
        if (this.selectedKanban.length >= 1) {
            if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                this.selectedKanban = [];
                this.selectedKanban.push(this.kanbanList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                const indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

                if (indexAll > -1) {
                    this.selectedKanban.splice(indexAll, 1);
                }
            }
        }
        if (this.selectedKanban.length > 0) {
            this.disablePartNumber = true;
        } else {
            this.disablePartNumber = false;
        }
    }
    onPartNoChange(event): void {
        if (this.selectedPartNo.length >= 1) {
            if (this.selectedPartNo[this.selectedPartNo.length - 1].part_number === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;
                this.renbanSearchValue = null;
                this.selectedPartNo = [];
                this.selectedPartNo.push(this.partNoList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;
                this.renbanSearchValue = null;
                let indexAll = this.selectedPartNo.findIndex((data) => data.part_number === 'ALL');

                if (indexAll > -1) {
                    this.selectedPartNo.splice(indexAll, 1);
                }
            }
        }
        if (this.selectedPartNo.length > 0) {
            this.disableKanban = true;
        } else {
            this.disableKanban = false;
        }
    }

    onRenbanChange(): void {
        if (this.selectedrenban.length >= 1) {
            if (this.selectedrenban[this.selectedrenban.length - 1].container_renban === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;
                this.renbanSearchValue = null;
                this.selectedrenban = [];
                this.selectedrenban.push(this.renbanList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;
                this.renbanSearchValue = null;
                let indexAll = this.selectedrenban.findIndex((data) => data.container_renban === 'ALL');

                if (indexAll > -1) {
                    this.selectedrenban.splice(indexAll, 1);
                }
            }
        }
    }
    onStatusChange(): void {
        if (this.seletedStatus.length >= 1) {
            if (this.seletedStatus[this.seletedStatus.length - 1].container_status === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                this.seletedStatus = [];
                this.seletedStatus.push(this.statusList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                let indexAll = this.seletedStatus.findIndex((data) => data.container_status === 'ALL');

                if (indexAll > -1) {
                    this.seletedStatus.splice(indexAll, 1);
                }
            }
        }
    }
    onModuleCodeChange(): void {
        if (this.seletedModuleCode.length >= 1) {
            if (this.seletedModuleCode[this.seletedModuleCode.length - 1].modulecode === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                this.seletedModuleCode = [];
                this.seletedModuleCode.push(this.moduleCodeList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                let indexAll = this.seletedModuleCode.findIndex((data) => data.modulecode === 'ALL');

                if (indexAll > -1) {
                    this.seletedModuleCode.splice(indexAll, 1);
                }
            }
        }
    }

    onModuleChange(): void {
        if (this.seletedModule.length >= 1) {
            if (this.seletedModule[this.seletedModule.length - 1].module_number === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                this.seletedModule = [];
                this.seletedModule.push(this.moduleList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                let indexAll = this.seletedModule.findIndex((data) => data.module_number === 'ALL');

                if (indexAll > -1) {
                    this.seletedModule.splice(indexAll, 1);
                }
            }
        }
    }

    onContainerChange(): void {
        if (this.selectedContainer.length >= 1) {
            if (this.selectedContainer[this.selectedContainer.length - 1].cc === 'ALL') {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                this.selectedContainer = [];
                this.selectedContainer.push(this.containerList[0]);
            } else {
                this.kanbanSearchValue = null;
                this.renbanSearchValue = null;
                this.renbanValue = null;
                this.renbanFrom = null;
                this.renbanTo = null;

                let indexAll = this.selectedContainer.findIndex((data) => data.cc === 'ALL');

                if (indexAll > -1) {
                    this.selectedContainer.splice(indexAll, 1);
                }
            }
        }
    }

    onVanDtChange() {
        this.renbanSearchValue = null;
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
    }

    getModifiedRowData(): any {
        const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
        return selectedRowData;
    }

    // download for normal grid
    exportAsXLSX(): void {
        this.removeMessage();

        this.spinner.show();

        // eastern time api

        const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
        let data = [];

        if (this.headerCheckboxChecked) {
            this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
                data.push(node.data);
            });
        } else {
            data = this.getModifiedRowData();
        }
        console.log('data1----', data);

        setTimeout(async () => {
            if (data.length < 1) {
                this.spinner.hide();
                this.warningMessage = warningMessage.noRowsSelected;

                window.scroll(0, 0);
            } else {
                if (this.rowData.length > 0) {
                    const exportData: any = [];

                    data.forEach((e) => {

                        const obj = {
                            dock:e.dock,
                            line:e.lines,
                            van_from:e.van_from,
                            van_to:e.van_to
                        };
                        exportData.push(obj);
                    });

                    const headers = [
                        'Dock',
                        'Line',
                        'Valid From',
                        'Valid To'
                    ];

                    const timestamp = easternCurrentDate

                        .split('.')[0]
                        .replace('T', '_')
                        .split('')

                        .filter((d) => d !== '-' && d !== ':')
                        .join('');

                    if (exportData.length > 0) {
                        console.log('exported data', exportData);

                        this.excelService.exportAsExcelFile(
                            exportData,
                            'Configuration Lines' + timestamp,

                            headers
                        );
                    } else {
                        this.warningMessage = warningMessage.noRowsSelected;
                    }
                    this.spinner.hide();
                }
            }
        }, 1000);
    }
    removeMessage() {
        this.warningMessage = '';
        this.successMessage = '';
    }
}
