import { Component } from '@angular/core';

@Component({
  selector: 'production-progress-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="4"
      pattern="[0-9]"
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class ProductionProgressEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() {}

  agInit(params: any): void {

    console.log("inside review chnages--------------" ,params.column)
    if (params.column === 'final') {
      params.data.tapQuantity = this;

      this.value = params.data.final;


      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    if (params.column === 'final') {
      params.data.tapQuantity = this;

      this.value = params.data.final;

       
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    

    if (params.column === 'rev_max_qty') {
      params.data.revMaxQtyText = this;
      this.value = params.data.rev_max_qty;
      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;
    if (
      this.params.data.checkedEditMode
     
     
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log("textupadted")
    console.log(" outside inside if",this.params)
    if (this.params.column === 'final') {
      if (this.value !== this.params.data.final) {
        console.log(" outside inside if",this.params)

        this.params.data.final = this.value;
        this.params.data.finalupadted = true;
      }
    } else {
      this.params.data.finalestdt = this.value;
    }


    // if (this.params.column === 'final') {
    //   console.log("inside if",this.params.data)
    //   this.params.data.final = this.value;
    //   this.params.data.finalupadted = true;

    //   if (
    //     (this.value || this.value?.toString() === '0') &&
    //     this.value?.toString() !== '-'
    //   ) {
    //     this.isEmpty = false;
    //   } else {
    //     this.isEmpty = true;
    //   }
    // } else if (this.params.column === 'rev_max_qty') {
    //   this.params.data.rev_max_qty = this.value;

    //   if (
    //     (this.value || this.value?.toString() === '0') &&
    //     this.value?.toString() !== '-'
    //   ) {
    //     this.isEmpty = false;
    //   } else {
    //     this.isEmpty = true;
    //   }
    // } else {
    //   this.params.data.finalquantity = this.value;
    // }
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  stopTyping() {
    return false;
  }

}
