import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { log } from 'console';


@Component({
  selector: 'app-parts-edit-dialog',
  templateUrl: './parts-edit-dialog.component.html',
  styleUrls: ['./parts-edit-dialog.component.scss']
})
export class PartsEditDialogComponent implements OnInit{

    addMasterEventEmitter = new EventEmitter();
    editMasterEventEmitter = new EventEmitter();
    master_id: any;
    master_name: any;
    key_item: any;
    data_item: any;
    business_entity: any;
    new_key_item: any;
    cstatus: any;
    showaddpopup: boolean = false;
    showeditpopup: boolean = false;
    popupname: any;
    dock: any;
    policyName: any;
    description: any;
    dataItem: any;
    enableError: boolean = false;
    userForm: UntypedFormGroup;
  
    addOrEditform: UntypedFormGroup = this.formBuilder.group({
      dock: ['', Validators.required],
      policyName: ['', Validators.required],
      description:[''],
      minDays:[''],
      maxDays:[''],
      effectiveFrom:['', Validators.required], 
      minBoxes:[''],
      maxBoxes:['']
    },
    // { validator: this.atLeastOnePhoneRequired}
    );
    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private readonly dialogeRef: MatDialogRef<PartsEditDialogComponent>,
      private readonly formBuilder: UntypedFormBuilder
    ) {}
    get addOrEdit() {
      return this.addOrEditform.controls;
    }
    access;
    ngOnInit(): void {debugger
      this.business_entity = localStorage.getItem('namcvalue');
      this.access = JSON.parse(localStorage.getItem('master'));
      console.log("data---",this.data.data.dock,this.data);
      
  
      if (this.data?.mode === 'edit') {
        if (this.data.data?.dock) {
          this.addOrEdit.dock.setValue(
            this.data.data.dock 
          );
          
        }
        if (this.data?.masterData) {
          this.addOrEdit.masterId.setValue(this.data?.masterData[0]);
          this.addOrEdit.masterId.disable();
        }
        if (this.data?.mkey) {
          this.addOrEdit.keyItem.setValue(this.data?.mkey);
        }
        if (this.data?.mdata) {
          this.addOrEdit.dataItem.setValue(this.data?.mdata);
        }
      }
    }
    // atLeastOnePhoneRequired(group : UntypedFormGroup) : {[s:string ]: boolean} {
    //   if (group) {
    //     if((group.controls['maxDays'].value) && (group.controls['minDays'].value) || 
    //     (group.controls['minBoxes'].value) && (group.controls['maxBoxes'].value)) {
    //       return null;
    //     }
    //   }
    //   // return { group.controls.maxDays.errors : true};
    // }
    
    submitted: boolean = false;
    addMasterDetails() {
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const addEmittedData = {
        dock: this.addOrEdit.dock.value.dock,
        policyName: this.addOrEdit.policyName.value.policy_name,
        description: this.addOrEdit.description.value,
        minDays: this.addOrEdit.minDays.value,
        maxDays: this.addOrEdit.maxDays.value,
        effectiveTo:this.addOrEdit.effectiveTo.value,
        effectiveFrom:this.addOrEdit.effectiveFrom.value,
        minBoxes: this.addOrEdit.minBoxes.value,
        maxBoxes: this.addOrEdit.maxBoxes.value,
      };
      console.log("addMaster--",this.addOrEdit,addEmittedData);
  
      this.addMasterEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }
    editRowMasterDetails() {
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const editEmittedData = {
        master_id: this.addOrEdit.masterId.value.master_id,
        master_name: this.addOrEdit.masterName.value.name,
        description: this.addOrEdit.description.value,
        data_item: this.addOrEdit.dataItem.value,
      };
      this.editMasterEventEmitter.emit(editEmittedData);
      this.dialogeRef.close();
      
    }
  
    callRespectiveFunction() {
      if (this.data?.mode === 'edit') {
        this.editRowMasterDetails();
      } else {
        console.log("add function is called");
        
        this.addMasterDetails();
      }
    }
    onKeyPress(event: KeyboardEvent) {
      const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
      const isNumber = /^[0-9]$/.test(event.key);
  
      // Allow number keys, allowed keys, and prevent default for others
      if (!isNumber && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    }
  }
  
