import {
    Component,
    OnInit,
    Inject,
    EventEmitter,
    ViewChild,
  } from '@angular/core';
  import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
  import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
  import { GriddataService } from 'src/app/services/griddata.service';
import { PartstmaintenanceService } from 'src/app/services/orderforecast/parts-maintenance/partsmaintenance.service';
import { PipeLookupService } from 'src/app/services/pipe-lookup/pipe-lookup.service';
import { warningMessage ,successMessage} from 'src/app/constants';
import { logErrors } from 'src/app/shared/logger';

  @Component({
    selector: 'app-configureline-grid',
    templateUrl: 'configureline.component.html',
    styleUrls: ['configureline.component.scss'],
  })
  export class ConfigurelinesComponent implements OnInit {
    editEventEmitter = new EventEmitter();
    rowData: any;
    @ViewChild('osPartsGrid') osPartsGrid;
    columnDefs;
    defaultColDef;
    rowSelection;
    gridOptions = {} as any;
    //gridOptions!: GridOptions;
    paginationPageSize;
    paginationNumberFormatter;
    pagination = true;
 
    context: any;
    vanningDT: any;
    vanningDT1: any;

    editType;
    suppressClickEdit = true;
    itContact: string;
    namcValue: string;
    namcName:string;
    user:string;
    userRole:string;
    dataCount: number = 0;
    offset: number = 0;
    warningMessage='';
    successMessage = '';


  

  
    editEnabled: boolean = false;
    freezeStatus: boolean = false;
    disablemodule: boolean = false;
    disableSearch : boolean = false;

    renbanList =[];
    moduleList =[];

    selectedRenban: any= [];
    selectedModule: any = [];
    enableTap:boolean=false;
    adjCodeList: any = [];
    editform: UntypedFormGroup = this.formBuilder.group({
      adjCode: [[]],
      adjReason: ['', Validators.required],
    });
    editFormcontrol = this.editform.controls;
    isdock: boolean;
    isline: boolean;
    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private readonly spinner: NgxSpinnerService,
      private readonly pipeLookupService: PipeLookupService,
      private readonly partstmaintenanceService: PartstmaintenanceService,


      private readonly formBuilder: UntypedFormBuilder,
      private readonly gridDataService: GriddataService,
      private readonly dialogRef: MatDialogRef<ConfigurelinesComponent>
    ) {
    this.rowData = data.modifiedRowData;
      this.getGridOptions();
      this.initializeData()
    }
  
    getGridOptions() {
      this.gridOptions = {} as any;
  
      this.gridOptions.headerHeight = 38;
  
      this.gridOptions.rowHeight = 28;
      this.gridOptions.floatingFiltersHeight = 0;
  
      this.gridOptions.rowBuffer = 20;
      this.gridOptions.suppressMovableColumns = true;
      this.gridOptions.enableCellTextSelection = true;
      this.gridOptions.suppressContextMenu = false;
      this.gridOptions.suppressClipboardPaste = true;
  
      this.gridOptions.defaultColDef = {
        sortable: true,
        suppressMenu: true,
        filter: false,
        floatingFilter: false,
  
        wrapText: true,
        autoHeight: true,
  
        suppressPaste: false,
  
        floatingFilterComponentParams: { suppressFilterButton: true },
        unSortIcon: true,
        icons: {
          sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
        },
        filterParams: {
          textCustomComparator(filter, value, filterText) {
            const filterTextLoweCase = filterText.toLowerCase();
  
            let valueLowerCase = '';
            if (value.params) {
              valueLowerCase = value.value.toString().toLowerCase();
            } else {
              valueLowerCase = value.toString().toLowerCase();
            }
            function contains(target, lookingFor) {
              if (target === null) {
                return false;
              }
              return target.indexOf(lookingFor) >= 0;
            }
            return contains(valueLowerCase, filterTextLoweCase);
          },
  
          // Debounce time - Delay before Floating filter search
          debounceMs: 1000,
  
          caseSensitive: true,
          suppressAndOrCondition: true,
          inRangeInclusive: true,
        },
      };

      return this.gridOptions;
      
    }
    initializeData() {
        this.onResetDropDown();
    
        this.itContact = localStorage.getItem('itContact');
    
        // this.spinner.show();
        this.rowData = [];
    
        if (localStorage.getItem('namcvalue')) {
          this.namcValue = localStorage.getItem('namcvalue');
        }
        if (localStorage.getItem('namcName')) {
          this.namcName = localStorage.getItem('namcName');
        }
        if (localStorage.getItem('workdayId')) {
          this.user = localStorage.getItem('workdayId');
        }
        if (localStorage.getItem('UserRoles')) {
          this.userRole = localStorage.getItem('UserRoles');
        }
    
    
        this.loadDrop();
      }

      onResetDropDown() {
        this.removeMessage();
        this.selectedModule = [];
        this.selectedRenban = [];
        this.disablemodule=true;
        this.enableTap=false;
        this.disableSearch=true;
    
        this.rowData = [];
      }   
         loadDrop() {
        const data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          page_name:'part-maintenance'
        };
    
    
        // const lookupSubscriptions = [
        //   this.pipeLookupService.getRenbanList(data),
        // ];
    
        // forkJoin(lookupSubscriptions).subscribe({
        //   next: (res) => {
        //     //if kanbanList, add ALL option
        //     this.moduleList = [];
        //     this.renbanList = [];
        //     this.renbanList.push(...res[0].body.data);

        //     this.spinner.hide();
        //   },
        // });
        this.spinner.hide();
        this.renbanList = [];
        this.moduleList = [];
    this.renbanList.push(
    { container_renban: 'BW' },
    { container_renban: 'EA' },
    { container_renban: 'R1' });
    this.moduleList.push(
    { module_number: '30' },
    { module_number: '31' },
    { module_number: '31' });

    this.spinner.hide();



    

      }

    ngOnInit() {

    }

    removeMessage() {
      this.warningMessage = '';
      this.successMessage = '';
    }
  

    
    RenbanList(){
      this.isdock=true;
      this.spinner.hide();
      this.renbanList = [];
      this.moduleList = [];
  this.renbanList.push(
  { container_renban: 'BW' },
  { container_renban: 'EA' },
  { container_renban: 'R1' });
  this.moduleList.push(
  { module_number: '30' },
  { module_number: '31' },
  { module_number: '31' });


      // this.spinner.show();
      // if (this.selectedRenban) {
      //   this.disablemodule = false;
      // } else {
      //   this.disablemodule = true;
      // }
      // const data = {
      //   business_entity: this.namcValue,
      //   workid: this.user,
      //   user_role: this.userRole,
      //   Renban: this.selectedRenban,
      //   page_name:'part-maintenance'
      // };
  
  
      // const lookupSubscriptions = [
      //   this.pipeLookupService.getModuleList(data)
      // ];
  
      // forkJoin(lookupSubscriptions).subscribe({
      //   error:(error) => {
      //     logErrors(error);
      //     this.spinner.hide();
      //   },
      //   next: (res) => {
      //     //if kanbanList, add ALL option
      //     this.moduleList = [];
      //     this.selectedModule=[];

        
      //     // if (res[0].body.data) {
      //     //   this.moduleList.push({ module_number: 'ALL' });
      //     // }
  
      //     this.moduleList.push(...res[0].body.data);
  

      //     this.spinner.hide();
      //   },
      // });

  
    }

    onModuleChange(){
      // if( this.selectedModule.length <= 0){
      //   this.disableSearch=true;
  
      // }
      // else{
      //   this.disableSearch=false;
      // }
  

      // if (this.selectedModule.length >= 1) {

 
      //   if (this.selectedModule[this.selectedModule.length - 1].module_number === 'ALL') {
      //     this.selectedModule = [];
      //     this.selectedModule.push(this.moduleList[0]);
      //   } else {
      //     const indexAll = this.selectedModule.findIndex((data) => data.module_number === 'ALL');
  
      //     if (indexAll > -1) {
      //       this.selectedModule.splice(indexAll, 1);
      //     }
      //   }
      // }
      this.spinner.hide();
      this.renbanList = [];
      this.moduleList = [];
  this.renbanList.push({ container_renban: 'ALL' },
  { container_renban: 'BW' },
  { container_renban: 'EA' },
  { container_renban: 'R1' });
  this.moduleList.push({ module_number: 'ALL' },
  { module_number: '30' },
  { module_number: '31' },
  { module_number: '31' });

  this.spinner.hide();

    };
    onSearch(offset) {
      this.removeMessage();

      if (offset === 0) {
        this.rowData = [];
        this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  
        this.offset = 0;
        this.spinner.show();
      }
      if(!this.selectedRenban.container_renban){
        this.rowData = [];
        this.warningMessage = warningMessage.selectToSearch;
        this.spinner.hide();
        return
      }

      let selectedrenban;
      let selectedModule
          selectedrenban = [];
            selectedrenban.push(this.selectedRenban.container_renban);

  

  
      // selectedrenban=this.selectedRenban;
      if (this.selectedModule.length > 0) {
        if (!this.selectedModule.some((a) => a.module_number === 'ALL')) {
          selectedModule = [];
          this.selectedModule.forEach((element) => {
            selectedModule.push(element.module_number);
          });
        }
      }
  console.log("this.seleted",this.selectedRenban)
      const data = {
        offset: offset,
        limit: 8000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        container_renban: selectedrenban,
        module_number: selectedModule,
        page_name:'tap'
      };
      this.enableTap=true;
      this.partstmaintenanceService.getPartmaintenanceData(data).subscribe({
        next: (res) => {
          if (res.body && res.body.data) {
            this.rowData.push(...res.body.data.data);
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            this.dataCount = parseInt(res.body.data.rowCount);
            const records = this.dataCount - (this.offset + 1) * 8000;
            if (records >= 1) {
              this.offset++;
              this.onSearch(this.offset * 8000);
            } else {
              this.spinner.hide();
                if(res.body.data.rowCount ==="0"){
                  this.enableTap=false;
                  this.warningMessage = "The Selected Renban/Module has been already Tapped, Kindly verify"
                }
            }
          } else {
            this.rowData = [];
            this.spinner.hide();
          }
  
        },
      });
      return true;
    }
  
    saveChanges(): any {
      // if(!this.isdock){
      //   return
      // }
      // if(!this.isline){
      //   return
      // }

      // if(!this.selectedRenban.container_renban && !this.selectedRenban.container_renban){
      //   this.rowData = [];
      //   this.warningMessage = 'Plaese select dock and line';
      //   this.spinner.hide();
      //   return
      // }

      // if(!this.selectedRenban.container_renban){
      //   this.rowData = [];
      //   this.warningMessage = 'Plaese select dock';
      //   this.spinner.hide();
      //   return
      // }
      // if(!this.selectedModule.module_number){
      //   this.rowData = [];
      //   this.warningMessage = 'Plaese select lines';
      //   this.spinner.hide();
      //   return
      // }
      // if (this.isdock){
      //   return
      // }



      this.editEventEmitter.emit(this.rowData);
      

  
    }
  }
  