<div style="display : inline-block;margin-top: -3px;" >
   
    <a id="delete">
        <img   class="detailiconhome"  src="assets/images/icon-delete.svg" 
        (click)="deleteSchedule('value')"
        title="delete" alt="Delete">
    </a>
   
   
   
    
</div>

