<div style="display : inline-block;margin-top: -3px;" >
    <a  id="edit">
        <img  *ngIf="showIcon" class="detailiconhome" (click)="editData($event)"  style = "margin-right: 2px;" src="./assets/images/Edit_icon.svg" title="edit" alt="Edit">
    </a>
    <a id="delete">
        <img  *ngIf="showIcon" class="detailiconhome" (click)="deleteData()" src="assets/images/icon-delete.svg" title="delete" alt="Delete">
    </a>
   
   
   
    
</div>

