<span
  ><img
    class="margin-imagetag-download"
	 width="20px"
    height="20px"
	(click)="showbreak()"
    align-self="center"
    src="./assets/images/clock.png"
    alt=""
	title="Show breaks"

/></span>