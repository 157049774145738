import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GriddataService } from 'src/app/services/griddata.service';

@Component({
  selector: 'app-review-changes',
  templateUrl: './review-changes.component.html',
  styleUrls: ['./review-changes.component.scss'],
})
export class ViewBreakComponent implements OnInit {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;





  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<ViewBreakComponent>
  ) {
    this.columnDefs = [
              {
          headerName: 'Shift No',
          field: 'shift_no',
          width: 150,
        },
        {
          headerName: 'Break From',
          field: 'time_from',
          width: 150,
        },
        {
          headerName: 'To Break',
          field: 'time_to',
          width: 150,
        },

      
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.rowData = data
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  enableSave: boolean = false;
  ngOnInit() {
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  
  }

}

