import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-breaks',
	templateUrl: './breaks-detailview.component.html',
	styleUrls: [],})
export class BreaksDetailviewComponent implements ICellRendererAngularComp {
  params;
  label: string;
  isDisable: boolean;
  showbutton: boolean = false;

  agInit(params): void {
    this.params = params;
    // this.label="DEVAN";

    this.params = params;

    // if (this.params.data.container_status == 'NAMC ARRIVED' ) {
    //   this.label = "SHOW BREAK";
    // }
    // if (this.params.data.container_status == 'USER DEVANNED') {
    // }
  

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick() {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        rowData: this.params.node.data,
        // ...something
      };
      this.params.onClick();
    }
  }
  showbreak() {
    this.params.context.componentParent.showbreak(
      this.params.data
    );

  }
}
