import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartsInventoryService {
  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.partsinventoryKey,
      }),
    };
  }

  partInventoryApiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.partInventoryApiUrl = environment.partsinventoryUrl;
  }

  getpartInventoryGrid(data): Observable<any> {
    return this.http.post<any>(
      `${this.partInventoryApiUrl}/getpartspolicydata`,
      data,
      this.getHttpCustomOptions()
    );
  }
  
}