import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { LookupService } from '../services/lookup/lookup.service';
import { GriddataService } from '../services/griddata.service';
import { ViewBreakComponent } from './breaks-detailview/review-changes/review-changes.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';


import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { OspService } from '../core/master-services/osp.service';

import {
  dateFilterComparator,
  dateComparator,
  resetSortingAndFilters,
  warningMessage,
  successMessage,
  checkAccessPipe,
} from '../constants';
import * as mm from 'moment-timezone';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { GridApi } from 'ag-grid-community';
import { BreaksDetailviewComponent } from './breaks-detailview/breaks-detailview.component';

@Component({
  selector: 'app-configuration-shifts',
  templateUrl: './configuration-shifts.component.html',
  styleUrls: ['./configuration-shifts.component.scss'],
})
export class ShiftConfigurationComponent implements OnInit, OnDestroy {
  namcValue = '';
  user = '';
  userRole = '';
  count = 0;
  files: any[];
  fileBuffer: any;

  exportData: any;
  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  seletedStatus: any = [];


  rowData = [];
  rowDatafinal =[];

  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  headerHeight: number;
  rowSelection;
  modalComponent = 'modal-component';
  shiftSubscription$: Subscription;
  gridApi: GridApi;
  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  pageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  detailRowHeight = 300;
  public detailCellRenderer;
  public isRowMaster;
  masterDetail: boolean;
  public icons;

  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  editEnabled: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;
  selectedDock: any = [];
  dockList: any = [];



  // Dropdowns


  showHideList: any = [
    { label: 'Shift No', value: 'shift_no' },
    { label: 'Day', value: 'day_of_week' },
    {
      label: 'Start Time',
      value: 'start_time',
    },
    {
      value: 'end_time',
      label: 'End Time',
    },

    {
      label: 'Duration',
      value: 'duration',
    },
    {
      label: 'From',
      value: 'date_from',
    },
    {
      label: 'To',
      value: 'date_to',
    },
    {
      label: 'Force',
      value: 'force',
    },
    // {
    //   label: 'Break detail',
    //   value: 'break',
    // },


  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  showGrid: boolean;
  itContact: string;

  namcName: string;
  errorData: any;
  onInitEstdate: any;
  easternDate: any;

  defaultSpinnerMessage = 'Loading... Please wait...';
  dataLoadingMessage = 'Loading data in Bulk! It might take a while, Please wait...';
  spinnerMessage = this.defaultSpinnerMessage;
  showDownloadUploadButtons: boolean = true;

  headerCheckboxChecked: boolean = false;
  defaultPageSize = 100000;

  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);
  today = moment().format('MM/DD/YYYY');
  isExist: boolean;
  offset: 0;
  isEditMode: boolean;
  warningMessage: string;
  successMessage: string;
  dataCount: number;
  public frameworkComponents

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly lookupService: LookupService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly ospService: OspService,
    private readonly matDialog: MatDialog) {
    // this.detailCellRenderer = 'breaksDetailviewComponent';
    this.frameworkComponents = { buttonRenderer: BreaksDetailviewComponent };


    {
      this.context = {
        componentParent: this,
        refreshHeader: new Subject<void>()
      };
    }
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    // this.shiftSubscription$.unsubscribe();
  }

  ngOnInit(): void {


    this.columnDefs = [
      // {
      //   headerName: '',
      //   field: 'rowCheckBox',
      //   width: 44,
      //   headerComponentFramework: HeaderCheckboxComponent,
      //   floatingFilterComponentFramework: GridRefreshComponent,
      //   floatingFilterComponentParams: { suppressFilterButton: true },
      //   cellClass: 'ui-grid-cell-contents-auto',

      //   cellRendererFramework: RowCheckboxComponent,
      //   floatingFilter: true,

      // },
      {
        headerName: '',
        field: '',
        sortable: false,
        width: 50,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          label: 'Click 2',
        },
        pinned: 'left',

      },
      {
        headerName: 'Shift No',
        headerTooltip: 'Shift Number',
        field: 'shift_no',
        sortable: true,
        width: 150,
        floatingFilter: true,
        // cellRenderer: 'agGroupCellRenderer',
        // icons: this.icons,
      },
      {
        headerName: 'Day',
        headerTooltip: 'Day of week',
        field: 'day_of_week',
        sortable: true,
        width: 150,
        floatingFilter: true,
        valueGetter: (params) => {
          switch (params.data.day_of_week) {
            case "1": return 'Sun';
            case "2": return 'Mon';
            case "3": return 'Tue';
            case "4": return 'Wed';
            case "5": return 'Thu';
            case "6": return 'Fri';
            case "7": return 'Sat';
            default: return '';  // Return empty string for any invalid value
          }

        }

      },
      {
        headerName: 'Start Time',
        field: 'start_time',
        sortable: true,
        width: 180,
        floatingFilter: true,
        headerTooltip: 'Shipping Carrier',
      },
      {
        headerName: 'End Time',
        headerTooltip: 'End Time',
        field: 'end_time',
        width: 180,
        floatingFilter: true,
        sortable: true,
      },

      {
        headerName: 'Duration',
        headerTooltip: 'Duration',
        field: 'day_offset',
        sortable: true,
        width: 180,
        floatingFilter: true,
        tooltipField: 'duration',
      },

      {
        headerName: 'From',
        headerTooltip: 'From Date(mm/dd/yyyy)',
        field: 'from_date',
        sortable: true,
        width: 200,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },
      {
        headerName: 'To',
        headerTooltip: 'To Date(mm/dd/yyyy)',
        field: 'to_date',
        sortable: true,
        width: 200,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },
      {
        headerName: 'Force',
        headerTooltip: 'Force',
        field: 'force_flag',
        sortable: true,
        width: 140,
        floatingFilter: true,
        tooltipField: 'force',
      },




    ];


    this.headerHeight = 45;


    this.rowSelection = 'multiple';
    this.rowSelection = 'multiple';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };


    this.shiftSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  initializeData(): void {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');
    this.rowDatafinal =[];
    this.rowData = [];
    this.offset = 0;
    this.isEditMode = false;
    this.editEnabled = this.isEditMode;
    this.showSpinner();

    this.namcValue = localStorage.getItem('namcvalue');

    this.user = localStorage.getItem('workdayId');

    this.userRole = localStorage.getItem('UserRoles');

    this.namcName = localStorage.getItem('namcName');



    this.loadDrop();
  }
  onDockChange(): void {
    if (this.selectedDock.length >= 1) {
      this.selectedDock = {
        dock: [this.selectedDock.dock],
      };
    }
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  canDeactivate() {
    return this.editEnabled;
  }
  showSpinner(message?: string) {
    if (!message) {
      this.spinnerMessage = this.defaultSpinnerMessage;
    } else {
      this.spinnerMessage = message;
    }
  }

  hideSpinner() {
    this.spinner.hide();
  }


  showbreak(data) {
    console.log("inside show breaks", data)
    let breaks = [];
    breaks.push(data)

    const result = this.rowData.filter(item =>
      breaks.some(criterion =>
        item.shift_no === criterion.shift_no &&
        item.shift_id === criterion.shift_id
      )
    );
    console.log("-------------------", result)
    //   var data1 = {
    //   modifiedRowData: result,
    // };



    console.log("inside show breaks", data)
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '250px';
    dialogConfig.width = '530px';

    dialogConfig.data = result;
    const dialogRef = this.dialog.open(ViewBreakComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      }
    });
  }

  access1;

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowDatafinal.length;
     
    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowDatafinal.length = 0;
    }

    this.onPageSizeChanged();
  }


  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getDock(),
      this.lookupService.getUserShowHideColumns(),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        this.dockList.push(...res[0].body.data);
        const { shift_columns } = res[1].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(shift_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }
  onSearch(offset) {
    this.removeMessage();
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }


    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.rowDatafinal = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowDatafinal);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let selectedDock;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 8000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: [this.selectedDock.dock]
    };
    interface  Break{
      shift_id: number;
      shift_no: string
      time_from: string;
      time_to: number;
    }

    this.ospService.getShiftDetails(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log('--------------------', res);
        if (res.body && res.body.shiftResponce) {
          this.rowData.push(...res.body.shiftResponce.data);
          console.log("this.rowdata", this.rowData)
          const uniqueData: Break[] = this.rowData.reduce<Break[]>((acc, current) => {
            const found = acc.find(item => item.shift_id === current.shift_id && item.shift_no === current.shift_no);
            if (!found) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.rowDatafinal=uniqueData;
          this.osPartsGrid.gridOptions.api.setRowData(uniqueData);
          this.dataCount = parseInt(res.body.shiftResponce.rowCount);
          const records = this.dataCount - (this.offset + 1) * 8000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 8000);
          } else {
            this.spinner.hide();
            if (res.body.shiftResponce.rowCount === "0") {
              this.warningMessage = "No data For the selected dock"

            }
          }
        } else {
          this.rowData = [];
          this.rowDatafinal =[]
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  shift_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.shift_columns = hideItems;
    if (!hideItems.length) this.shift_columns = [{}];
  }
  saveShowHide() {
    let shift_columns = this.shift_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (shift_columns) {
      this.lookupService.updateUserShowHideColumns( { shift_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }





  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';

    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      }
    });
  }



  // getModifiedRowData(): any {
  //   const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
  //   return modifiedRowData;
  // }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  checkIfAnySelected() {
    if (
      this.selectedDock.length != 0

    ) {
      return true;
    }

    return false;
  }




  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  onResetDropDown() {
    this.removeMessage();
    this.selectedDock = [];

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.offset = 0;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  // exportAsXLSX(): void {
  //   this.spinner.show();
  //   let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
  //   console.log(easternCurrentDate);

  //   let data = [];
  //   if (this.headerCheckboxChecked) {
  //     console.log('Hello');
  //     this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
  //       data.push(node.data);
  //     });
  //   } else {
  //     data = this.getSelectedRowData();
  //   }

  //   setTimeout(async () => {
  //     if (data.length > 0) {
  //       const exportData: any = [];

  //       data.forEach((e) => {
  //         const obj = {
  //           container_status: e.container_status,
  //           container_renban: e.container_renban,
  //           shipping_carrier: e.vessel_company,
  //           vessel_name: e.vessel_name,
  //           container_number: e.container_number,
  //           tmc_van_completion_date: e.tmc_van_completion_date,
  //           tmc_port_departure: e.tmc_port_departure,
  //           estimated_namc_arrival: e.estimated_namc_arrival,
  //           actual_namc_arrival: e.actual_namc_arrival,
  //           devan_date: e.devan_date,
  //           updated_userid: e.updated_userid,
  //           updated_date_time: e.updated_date_time,
  //         };
  //         exportData.push(obj);
  //       });
  //       const headers = [
  //         'Status',
  //         'Renban',
  //         'Shipping Carrier',
  //         'Vessel Name',
  //         'Container No',
  //         'Van Comp DT',
  //         'Vess Sail DT',
  //         'Est Arr DT',
  //         'Act Arr DT',
  //         'Devan DT',
  //         'User',
  //         'Updated DT',
  //       ];

  //       const timestamp = easternCurrentDate

  //         .split('.')[0]
  //         .replace('T', '_')
  //         .split('')
  //         .filter((d) => d !== '-' && d !== ':')
  //         .join('');

  //       if (exportData.length > 0) {
  //         this.excelService.exportAsExcelFile(exportData, 'ContainerMaintenance' + timestamp, headers);
  //       }
  //     } else {
  //       this.warningMessage = warningMessage.noRowsSelected;
  //     }

  //     this.hideSpinner();
  //   }, 1000);
  // }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }


}