import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-header',
  template: ` <p><span class="v1"></span><span class="name">{{headerName}}</span></p> `,
  styles: [
    `
      .name {
        font: normal normal bold 13px / 16px Roboto, sans-serif;
        letter-spacing: 0px;
        color: #161718;
        opacity: 1;
        white-space: pre-line;
        text-align: center;
      }
    `,
  ],
})
export class CustomHeaderComponent {
  headerName: string;

  agInit(params: any): void {
    this.headerName = params.displayName; // Capture the display name passed from the grid configuration

    console.log(this.headerName,'plsgod')
  }
}
