export interface adjustmentType {
  adj_type: string;
  display: string;
}

// part.model.ts
export interface Part {
  partNo?: string;
  description?: string;
  // Add other properties as needed
}


const adjustmentTypesMaster: adjustmentType[] = [
  { adj_type: 'ALL', display: 'ALL' },
  { adj_type: 'BOA', display: 'Back Order (BOA)' },
  { adj_type: 'BOT', display: 'Build Out (BOT)' },
  { adj_type: 'MAN', display: 'Manual (MAN)' },
  { adj_type: 'NVEH', display: 'Non Vehicle (NVEH)' },
  { adj_type: 'NVSH', display: 'Non Vehicle Vendor Share (NVSH)' },
  { adj_type: 'SCRP', display: 'Scrap (SCRP)' },
  { adj_type: 'VEH', display: 'Vehicle (VEH)' },
  { adj_type: 'VVSH', display: 'Vehicle Vendor Share (VVSH)' },
  { adj_type: 'CMS', display: 'CMS' },
  { adj_type: 'FOR', display: 'FOR' },
  { adj_type: 'SIS', display: 'SIS' },
  { adj_type: 'SRVA', display: 'SRVA' },
  { adj_type: 'SRVO', display: 'SRVO' }
];


export function parseAdjustmentType(adjustmentTypes: adjustmentType[]) {
  
  return adjustmentTypes.map((adjType) => {
    return adjustmentTypesMaster.filter((adjTypeMasterRow) => {
      return adjTypeMasterRow.adj_type === adjType.adj_type;
    })[0];
  });
}
