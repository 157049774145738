import { Inject, Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class OSPStateService {
  state: OSPState;
  private currentState;
  stateValue;
  private guidStateSource = new BehaviorSubject<string>('');
  guidState = this.guidStateSource.asObservable();
  private nameStateSource = new BehaviorSubject<string>('');
  nameState = this.nameStateSource.asObservable();
  private roleStateSource = new BehaviorSubject<string>('');
  roleState = this.roleStateSource.asObservable();
  private IDStateSource = new BehaviorSubject<string>('');
  IDState = this.IDStateSource.asObservable();
  private companyStateSource = new BehaviorSubject<string>('');
  companyState = this.companyStateSource.asObservable();

  private agGridLicenseKeyState = new BehaviorSubject<boolean>(false);
  agGridLicenseKeyStateValue = this.agGridLicenseKeyState.asObservable();

  constructor() {
    this.state = {
      namc: '',
      WorkdayID: '',
      Name: '',
      Role: '',
    };
    this.currentState = new BehaviorSubject<OSPState>(this.state);
    this.stateValue = this.currentState.asObservable();
  }

  setGUIDState(name: string) {
    this.guidStateSource.next(name);
  }
  setNameState(name: string) {
    this.nameStateSource.next(name);
  }
  setIDState(ID: string) {
    this.IDStateSource.next(ID);
  }
  setRoleState(role: string) {
    this.roleStateSource.next(role);
  }
  setCompanyState(company: string) {
    this.companyStateSource.next(company);
  }

  getNamc(): Observable<any> {
    return this.stateValue;
  }

  setNamc(namc: any) {
    this.currentState.next(namc);
  }

  setLicenseKey(value: boolean) {
    this.agGridLicenseKeyState.next(value);
  }

  // returns whether ag grid license has been set or not
  getLicenseKeySetValue(): Observable<boolean> {
    return this.agGridLicenseKeyStateValue;
  }
}

export interface OSPState {
  namc: string;
  WorkdayID: string;
  Name: string;
  Role: string;
}
