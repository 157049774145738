import { Component } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-airorder-newrow',
  template: `
    <button type="button" class="devanall" title="All container must be in NAMC ARRIVED status">DEVAN ALL</button>
  `,
  styles: [
    `
      .badge {
        width: 94px;
        font-size: 12px;
        border-radius: 35px;
        font-weight: 600;
      }
    `,
  ],
})




export class AirorderNewrowComponent implements IFloatingFilter {
  params: IFloatingFilterParams;

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
    console.log('Im am called')
  }

  onParentModelChanged(parentModel: any): void {
    // Handle updates when parent model changes
  }
}

