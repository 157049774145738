import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  // Set an initial value (e.g., an empty array or null)
  private filteredDataSource = new BehaviorSubject<any[]>([]);  // Initial value is an empty array
  filteredData$ = this.filteredDataSource.asObservable();

  // Send updated filtered data to subscribers
  sendFilteredData(data: any): void {
    this.filteredDataSource.next(data);
  }

  // Optionally, you can get the current value of the filtered data
  getCurrentFilteredData(): any {
    return this.filteredDataSource.getValue();
  }

  // private orderDataSubject = new BehaviorSubject<any>(null);
  // orderData$ = this.orderDataSubject.asObservable();

  // setOrderData(data: any) {
  //   this.orderDataSubject.next(data);
  // }

  // clearOrderData() {
  //   this.orderDataSubject.next(null);
  // }
}

