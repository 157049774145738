import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class AirfreightApicallsService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');
  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      'X-Api-Key': this.environment.airOrderKey,
    });
  };


  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.airOrderKey,
      }),
    };
  };

  httpCustomOptions1 = () => {
    return {
      params: {
        business_entity: this.getBusinessEntity(),
        user_role: this.getUserRole(),
        workid: this.getWorkID(),
      },
      headers: this.getApiHeaders(),
    };
  };

  apiUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.airOrderUrl;
  }
  app;

  getAirOrderData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/getairfreight-data`, data, this.httpCustomOptions());
  }
  addAirOrderDetails(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/add-air-order`, data, this.httpCustomOptions());
   // return this.http.post('http://localhost:3000/addair',data)
  }
  getPartNumber(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-parts`, this.httpCustomOptions1());
  }
  getKanban(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-kanban`, this.httpCustomOptions1());
  }
  getDock(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-dock`, this.httpCustomOptions1());
  }
  getDestCode(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-destcodes`, this.httpCustomOptions1());
  }
  getStatus(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getstatus`, this.httpCustomOptions1());

  }
  // getDeleteAirorder(data): Observable<any> {
  //   return this.http.delete<any>(`${this.apiUrl}/deleteAirorder`,data, this.httpCustomOptions());
  // }
  getDeleteAirorder(
    data
  ): Observable<any> {
    const httpCustomOptions = {
      body: 
        data
      ,
      headers: this.getApiHeaders(),
    };

    return this.http.delete<any>(
      `${this.apiUrl}/deleteAirorder`,

      httpCustomOptions
    );
  }
  getReason(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getReasonCode`, this.httpCustomOptions1());
  }
  



}
