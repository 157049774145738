import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GridOptions } from 'ag-grid-community';
import { GriddataService } from '../../services/griddata.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class ProgressEditDialogComponent implements OnInit {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = false;
  context: any;
  editType;
  suppressClickEdit = true;
  //rightAlligned = 'ag-right-aligned-cell';

  editEnabled: boolean = false;
  freezeStatus: boolean = false;

  adjCodeList: any = [];

  editform: UntypedFormGroup = this.formBuilder.group({
    adjCode: [[], Validators.required],
    adjReason: [''],
  });
  editFormcontrol = this.editform.controls;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<ProgressEditDialogComponent>
  ) {
    this.columnDefs = [
      {
        headerName: 'Production Date',
        headerTooltip: 'Production Date(mm/dd/yyyy)',
        field: 'production_date',
        sortable: true,
        width: 130,
      },
      {
        headerName: 'Int Plan Vol',
        field: 'int_plan_vol',
        width: 150,
      },
      {
        headerName: 'Final',
        field: 'final',
        width: 150,
      },
      {
        headerName: 'ALC',
        field: 'ALC',
        headerTooltip: 'ALC',
        width: 150,

        //cellClass: this.rightAlligned,
      },
      {
        headerName: 'Cumul Plan NET',
        field: 'cumul_plan_net',
        sortable: true,
        width: 150,

        //cellClass: this.rightAlligned,
      },
      {
        // headerName: 'Error',
        field: 'error',
        flex: 1,

        headerComponentParams: {
          template:
            '<span class="ag-header-cell-text" style="color:#D20000">Error</span>',
        },
        cellStyle: {
          color: '#D20000',
        },
        cellRenderer: (params) => {
          const html = params.value
            .split('\n')
            .map((line) => `<li style="list-style-type:none;">${line}</li>`)
            .join('\n');
          return `${html}`;
        },
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.paginationPageSize = data.modifiedRowData.length;


    this.rowData = data.modifiedRowData;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    // this.gridOptions.domLayout = 'autoHeight';
    // this.gridOptions.suppressRowClickSelection = true;
    // this.gridOptions.enableCellTextSelection = true;
    // this.gridOptions.suppressCopyRowsToClipboard = true;
    // this.gridOptions.enableRangeSelection = true;
    // this.gridOptions.rowSelection = 'single';
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,
      // suppressSizeToFit: true,
      // headerClass: 'grid-header-class',
      // cellStyle(params) {
      //   return {
      //     'line-height': '36px',
      //   };
      // },

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  enableSave: boolean = false;

  ngOnInit() {
    const errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  }
}
