import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../services/excel.service';
import { GriddataService } from '../services/griddata.service';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { OSPStateService } from '../services/osp-state.service';
import { dateFilterComparator, resetSortingAndFilters, warningMessage,successMessage } from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { AirFreightActionsComponent } from './air-freight-actions/air-freight-actions.component';
import { OspService } from '../core/master-services/osp.service';

import { RowNode, GroupCellRendererParams, RowGroupingDisplayType } from 'ag-grid-community';
import { AirfreightApicallsService } from '../services/air-freight-validation/airfreight-apicalls.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ValidationService } from '../services/air-freight-validation/validations.service';
import { DataServiceService } from '../services/air-freight-validation/data-service.service';
import { AirorderDeleteComponent } from './delete-airorder/airorder-delete.component';

@Component({
  selector: 'app-air-freight-order',
  templateUrl: './air-freight-order.component.html',
  styleUrls: ['./air-freight-order.component.scss'],
})
export class AirFreightOrderComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  count = 0;
  rowData = [];
  originalRowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  private isInitialized = false;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  successMessage = '';
  canceledRecord: any = [];
  isShowArrived: boolean = false;
  isShowCanceled: boolean = false;
  isNavigatedFromSecond: boolean = false; // Flag to track navigation source
  autoGroupColumnDef;
  groupRowsSticky;
  groupDefaultExpanded = 1;
  showOpenedGroup = false;
  groupSelectsChildren;
  suppressRowClickSelection;
  suppressAggFuncInHeader;
  public groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  dataFromSecond: any;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  onInitVanningFrom: any;
  onInitVanningTo: any;
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedStatus: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  adjList: any = [];
  selectedAdjlist: any = [];
  addAirOrderPart: any = [];
  addAirOrdereKanban: any = [];

  vanningFrom: any;
  vanningTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  ordDate: any;
  ordDateTo: any;
  onInitordDate: any;
  onIntordDateTo: any;

  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  statusList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  modalComponent = 'modal-component';
  addGetResponse = [];
  minVanningFrom;
  showHideList: any = [
    // {
    //   label: 'Air Order No',
    //   value: 'air_order_no',
    // },
    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'Air Case Code',
      value: 'air_case_code',
    },
    {
      label: 'Part Number',
      value: 'part_no',
    },

    {
      label: 'Kanban',
      value: 'EMPLOYEE',
    },
    {
      label: 'Arrival DT',
      value: 'arrival_date',
    },
    {
      label: 'No.Of.Pieces',
      value: 'number_of_pieces',
    },
    {
      label: 'No.Of.Lots',
      value: 'number_of_lots',
    },

    {
      label: 'Dest Code',
      value: 'dest_code',
    },
    {
      label: 'Status',
      value: 'air_order_status',
    },
    {
      label: 'Van Center Confirmed',
      value: 'kvc_conf',
    },
    {
      label: 'Van Center ETA DT',
      value: 'eta_datetime',
    },
    {
      label: 'Renban',
      value: 'Case_no',
    },
    { value: 'updated_userid', label: 'User' },
    { value: 'updated_date_time', label: 'Updated DT' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  orderAdded: any;
  gridApi: any;
  pendingOrderData: any;
  itContact: string;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,

    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    private router: Router,
    private validationService: ValidationService,
    private dataService: DataServiceService
  ) {
    this.context = {
      componentParent: this,
    };

    // this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
    //   const currentNavigation = this.router.getCurrentNavigation();
    //   if (currentNavigation?.extras.state) {
    //     this.dataFromSecond = currentNavigation.extras.state.data;
    //     this.isNavigatedFromSecond = true; // Set the flag when data is received
    //     console.log('Received data:', this.dataFromSecond);
    //     this.handleOrderAdded(this.dataFromSecond);
    //   } else {
    //     console.log('No navigation state available');
    //   }
    // });
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    console.log('ngoninit called');
    this.validationService.orderData$.subscribe((orderData) => {
      if (orderData) {
        this.pendingOrderData = orderData; // Store until init is complete
      }
    });
    this.initializeGridColumns();

    this.adjustSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
      if (this.pendingOrderData) {
        console.log('Received data:', this.pendingOrderData);
        this.handleOrderAdded(this.pendingOrderData);
        //this.validationService.clearOrderData(); // Clear after handling
        this.pendingOrderData = null; // Reset
      }
    });
  }

  private initializeGridColumns() {
    this.columnDefsMaster = [
      {
        headerName: 'Actions',
        cellRendererFramework: AirFreightActionsComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        field: 'actions',
        width: 100,
        sortable: false,
        floatingFilter: true,
        pinned: 'left',
        cellRendererParams: {
          // Pass a parameter to the component to determine if it should render
          showIcon: (params) => params.node.group, // Show icon only if it's not a group
        },
      },
      {
        headerName: 'Air Order No',
        field: 'air_order_no',
        sortable: true,
        width: 210,
        floatingFilter: true,
        tooltipField: 'Air Order No',
        headerTooltip: 'Air Order No',
        rowGroup: true,
        hide: true,
        checkboxSelection: (params) => params.node.group,
        headerCheckboxSelection: true,
      },

      {
        headerName: 'Dock',
        field: 'dock',
        width: 150,
        floatingFilter: true,
        tooltipField: 'dock',
        headerTooltip: 'Dock',
      },
      {
        headerName: 'Air Case Code',
        field: 'air_case_code',
        width: 150,
        floatingFilter: true,
        tooltipField: 'air_case_code',
        headerTooltip: 'Air case Code',
      },

      {
        headerName: 'Part Number',
        field: 'part_no',
        width: 170,
        floatingFilter: true,
        tooltipField: 'part_no',
        headerTooltip: 'Part Number',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Kanban',
        tooltipField: 'Kanban',
      },
      {
        headerName: 'Arrival DT',
        field: 'arrival_date',
        headerTooltip: 'Arrival Date',
        width: 176,
        floatingFilter: true,
        supressMenu: true,
        tooltipField: 'Arrival Date',
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'No. Of Pieces',
        field: 'pieces',
        headerTooltip: 'No. Of Pieces',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'No. Of Pieces',
      },
      {
        headerName: 'No. Of Lots',
        headerTooltip: 'No. Of Lots',
        field: 'order_lot',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'No. Of Lots',
      },
      {
        headerName: 'Dest Code',
        field: 'dest_code',
        sortable: true,
        width: 150,
        floatingFilter: true,
      },
      {
        headerName: 'Status',
        field: 'air_order_status',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'air_order_status',
      },

      {
        headerName: 'Van Center Confirmed',
        field: 'kvc_conf',
        width: 150,
        floatingFilter: true,
        headerTooltip: 'Van Center Confirmed',
      },

      {
        headerName: 'Van Center ETA Time',
        field: 'vanning_date',
        width: 170,
        headerTooltip: 'Kvc Eta Time(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
      },
      {
        headerName: 'Renban',
        field: 'adj_type',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: 'Order DT',
        field: 'date_raised',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: 'Updated DT',
        field: 'date_time',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.autoGroupColumnDef = {
      headerName: 'Air Order No',
      field: 'air_order_no',
      sortable: true,
      width: 250,
      rowgroup: true,
      cellRenderer: 'agGroupCellRenderer',
      floatingFilter: true,
      
      cellRendererParams: {
        checkbox: (params) => params.node.group, // Only show checkbox if it's not a group
      } as GroupCellRendererParams,
      valueGetter: (params) => {
        if (params.node.group) {
          return params.data.air_order_no;
        }
        return '';
      },
      headerCheckboxSelection: true,
      
    };

    this.rowSelection = 'multiple';
    this.groupSelectsChildren = true;
    this.suppressRowClickSelection = true;
    this.suppressAggFuncInHeader = true;
    this.editType = 'fullRow';
    this.showOpenedGroup = true;
    this.groupRowsSticky = true;

    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = (params) => {
      this.gridApi = params.api;
    };
  }

  initializeData() {
    console.log('initialized data ');
    this.spinner.show();


    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }

    this.loadDrop();
    this.onResetDropDown();

    

    this.originalRowData = [...this.rowData]; // Keep a copy of the original data

    this.setPageSizeToAll();
  }
  checkIfAnySelected() {
    if (this.selectedDestCode.length != 0) {
      return true;
    }

    return false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    this.removeMessage();
    this.spinner.show();
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }
    // if (!this.isNavigatedFromSecond) {
    //   console.log('navigation if');
    //   // Clear message if not navigated from second
    //   this.removeMessage();
    // }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
      this.offset = 0;
      this.spinner.show();
    }
    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectdest;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;
    let selectedPartNo;
    let selectedDestCode;
    let selectedContainer;
    let selectedStatus;
    let selectedDock;
    let selectedSpecialist;
    let selecteAdjlist;
    let selectedAdjlist;

    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.part_no === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.part_no);
        });
      }
    }

    if (this.selectedDestCode.length > 0) {
      if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
        selectedDestCode = [];
        this.selectedDestCode.forEach((element) => {
          selectedDestCode.push(element.dest_code);
        });
      }
    }

    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cont_code);
        });
      }
    }

    if (this.selectedStatus.length > 0) {
      if (!this.selectedStatus.some((a) => a.customer_supp === 'ALL')) {
        selectedStatus = [];
        this.selectedStatus.forEach((element) => {
          selectedStatus.push(element.customer_supp);
        });
      }
    }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
    }

    if (this.selectedAdjlist.length > 0) {
      if (!this.selectedAdjlist.some((a) => a.adj_type === 'ALL')) {
        selectedAdjlist = [];
        this.selectedAdjlist.forEach((element) => {
          selectedAdjlist.push(element.adj_type);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_no: selectedPartNo,
      dest_code: selectedDestCode,
      dock: selectedDock,
      date_raised_from: this.ordDate,
      date_raised_to: this.ordDateTo,
    };

    this.airFreightService.getAirOrderData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log(res, 'getair');
        if (res.body.data) {
          this.rowData.push(...res.body.data.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.spinner.hide();
          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    

    const data = {
      business_entity: '01',
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.airFreightService.getPartNumber(),
      this.airFreightService.getKanban(),
      this.airFreightService.getDock(),
      this.airFreightService.getDestCode(),
      this.airFreightService.getStatus(),
      this.lookupservice.getUserShowHideColumns(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        console.log(res);
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.containerList = [];
        this.statusList = [];
        this.dockList = [];
        this.adjList = [];
        this.specialistList = [];
        if (res[1].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[1].body.data);

        if (res[0].body.data) {
          this.partNoList.push({ part_no: 'ALL' });
        }
        this.partNoList.push(...res[0].body.data);

        if (res[1].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }
        this.kanbanList.push(...res[0].body.data);

        if (res[2].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }
        this.destCodeList.push(...res[3].body.data);

        if (res[4].body.data) {
          this.statusList.push({ air_order_status: 'ALL' });
        }
        this.statusList.push(...res[4].body.data);
        const { air_order_columns } = res[5].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(air_order_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });

    //   },
    // });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedStatus = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedAdjlist = [];
    this.selectedLifecycle = [];
    this.ordDate = this.onInitordDate;
    this.ordDateTo = this.onInitordDate;

    this.kanbanSearchValue = null;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  air_order_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.air_order_columns = hideItems;
    if (!hideItems.length) this.air_order_columns = [{}];
  }

  saveShowHide() {
    this.spinner.show();

    let air_order_columns = this.air_order_columns;
    if (air_order_columns) {
      this.lookupservice.updateUserShowHideColumns({ air_order_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].part_no === 'ALL') {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex((data) => data.part_no === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    // if (this.selectedPartNo.length > 0) {
    //   this.disableKanban = true;
    // } else {
    //   this.disableKanban = false;
    // }
  }

  onDestCodeChange(): void {
    if (this.selectedDestCode.length >= 1) {
      if (this.selectedDestCode[this.selectedDestCode.length - 1].dest_code === 'ALL') {
        this.selectedDestCode = [];
        this.selectedDestCode.push(this.destCodeList[0]);
      } else {
        let indexAll = this.selectedDestCode.findIndex((data) => data.dest_code === 'ALL');

        if (indexAll > -1) {
          this.selectedDestCode.splice(indexAll, 1);
        }
      }
    }
  }

  statusChange(): void {
    if (this.selectedStatus.length >= 1) {
      if (this.selectedStatus[this.selectedStatus.length - 1].air_order_status === 'ALL') {
        this.selectedStatus = [];
        this.selectedStatus.push(this.statusList[0]);
      } else {
        let indexAll = this.selectedStatus.findIndex((data) => data.air_order_status === 'ALL');

        if (indexAll > -1) {
          this.selectedStatus.splice(indexAll, 1);
        }
      }
    }
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  adjChange(): void {
    if (this.selectedAdjlist.length >= 1) {
      if (this.selectedAdjlist[this.selectedAdjlist.length - 1].adj_type === 'ALL') {
        this.selectedAdjlist = [];
        this.selectedAdjlist.push(this.adjList[0]);
      } else {
        let indexAll = this.selectedAdjlist.findIndex((data) => data.adj_type === 'ALL');

        if (indexAll > -1) {
          this.selectedAdjlist.splice(indexAll, 1);
        }
      }
    }
  }

  specialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE === 'ALL') {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex((data) => data.EMPLOYEE === 'ALL');

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }
  exportAsXLSX(): void {
    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              PartNO: e.item_id,
              partdescription: e.part_description,
              kanban: e.kanban,
              Qpc: e.order_lot,
              dock: e.dock,
              Specialist: e.EMPLOYEE,
              container: e.cont_code,
              adjdt: e.adj_applied_date,
              vandt: e.vanning_date,
              adjtype: e.adj_type,
              adjquantity: parseInt(e.adj_qty),
              adjreason: e.adj_reason,
              updatedt: e.date_time,
              User: e.userid,
            };
            exportData.push(obj);
          });

          const headers = [
            'PART NO',
            'PART DESCRIPTION',
            'KANBAN',
            'QPC',
            'DOCK',
            'SPC',
            'CC',
            'ADJ DT',
            'VAN DT',
            'ADJ TYPE',
            'ADJ QTY',
            'ADJ REASON',
            'UPDATED DT',
            'USER',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'AdjustmentInquiry_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  addNew() {
    this.router.navigate(['add-air-order']);
  }

  // handleOrderAdded(orderData: any) {
  //   this.onSearch(0)

  //   // console.log('handle order', orderData);

  //   // this.successMessage = 'hi';

  //   // console.log(orderData.body.addResponse);
  //   // if (orderData.statusCode == '200') {
  //   //   this.onSearch(0)
  //   //   this.successMessage = `Order Created! ${orderData.body.addResponse}`;
  //   // }
  //   // this.isNavigatedFromSecond = false;
  // }

  handleOrderAdded(orderData: any) {
    this.successMessage='hi...'
    console.log('Handling order added with data:', orderData);
    this.successMessage = `Order Created! ${orderData.body.addResponse}`;

    //this.onSearch(0);

    // More logging to ensure flow
    console.log('Called onSearch with parameter 0');

    // Your additional logic here...
  }

  getSelectedRowData(): any {
    // Get the selected rows using the grid API
    const selectedNodes = this.gridOptions.api.getSelectedNodes();

    // Map the selected nodes to their data
    const selectedRowData = selectedNodes.map((node) => node.data);

    console.log(selectedRowData, 'selected row data...');

    return selectedRowData;
  }

  onEditMode(): void {
    this.removeMessage();
    let data = [];
    data = this.getSelectedRowData();

    console.log(data, 'data.......');

    // console.log(data, 'editcheck');
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;
      this.isEditMode = false;
      this.editEnabled = false;
      window.scroll(0, 0);
    }
  }
  userCheckChanged(rowIndex) {
    let childRows = this.getChildRowsForParent(rowIndex);
    console.log(childRows);
    this.dataService.sendFilteredData(childRows);
    this.router.navigate(['add-air-order']);
  }
  userDeleteChanged(rowIndex){
    let childRows = this.getChildRowsForParent(rowIndex);
    console.log(childRows);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '200px';
    dialogConfig.width = '460px';
    dialogConfig.data = childRows;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);
    const data = {
      offset:0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: childRows
    };
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.airFreightService.getDeleteAirorder(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.data.table1DeletedCount >= 1 || data.body.data.table2DeletedCount >= 1) {
              this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);

            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    });




      // next: (res) => {
      //   console.log(res, 'getair');
      //   if (res.body.data) {
      //     console.log(res)
      //     this.spinner.hide();
      //          } else {
      //           this.spinner.hide();
      //         }

      //     this.setPageSizeToAll();
        
      // },

  }

  // Function to get child rows for a given parent row
  getChildRowsForParent(rowIndex: number) {
    console.log(rowIndex, 'index............');

    // Get the parent rowNode using the rowIndex
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (rowNode && rowNode.group) {
      // Ensure the parent row is expanded (just in case it is collapsed)
      if (!rowNode.expanded) {
        rowNode.setExpanded(true); // Expand the parent to make sure children are accessible
      }

      // Now get the child rows after expanding
      const childRows = rowNode.childrenAfterGroup; // This will be the child rows associated with this parent

      // Extract only the 'data' from each child row
      const childData = childRows.map((childRowNode) => childRowNode.data);

      // Return the array of child data
      return childData;
    } else {
      console.log('This is not a parent row or the rowNode could not be found.');
      return []; // Return empty array if not a valid parent row
    }
  }
}
