import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-configurelines',
  template: `
  <span class="edit_icon">
  <img class="detailiconhome" (click)="devan('value')" src="assets/images/icon-delete.svg"
      alt="download icon" title="delete" />
</span>
`,
  styles: [
    
  ],
})
export class DeleteLinesComponent implements ICellRendererAngularComp {
  params;
  label: string;
  isDisable: boolean;
  showbutton: boolean = false;

  agInit(params): void {
    this.params = params;
    console.log("devannutton", params);
    // this.label="DEVAN";

    this.params = params;

  

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        // ...something
      };
      this.params.onClick(params);
    }
  }
  devan(value) {

    this.params.context.componentParent.deleteline(
      this.params.data
    );

  }

  }

