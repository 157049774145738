import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as mm from 'moment-timezone';

import { forkJoin, Subscription } from 'rxjs';
import { LookupService } from '../services/lookup/lookup.service';
import { checkAccess, dateFilterComparator, resetSortingAndFilters, validateVanningDates } from '../constants';

import { ProductionProgressEditorComponent } from './progress-grid-text-edit.component';

import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';

import { OSPStateService } from '../services/osp-state.service';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { freezingMesssage } from '../constants';
import { ProgressEditDialogComponent } from './edit-dialog/edit-dialog.component';
import { warningMessage, successMessage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, IDetailCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-production-progress-maintenance',
  templateUrl: './production-progress.component.html',
  styleUrls: ['./production-progress.component.scss'],
})
export class ProductionProgressComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;
  // Rameshkumar has asked to hide upload and download for Phase 1
  // Rameshkumar and Sunil asked to show buttons again

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  public groupDefaultExpanded = 1;
  rowData: any = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  public detailCellRendererParams;
  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent,
    };
  
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  public themeClass: string =
  "ag-theme-balham";

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  supplierList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  adjCodeList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;
  treeData

  showHideList: any = [
    // { value: 'vanning_date', label: 'Vanning DT' },
    { value: 'production_date', label: 'Production Date' },
    { value: 'vehicle_type', label: 'Module Type' },
    { value: 'shift', label: 'Shift' },
    { value: 'plan_vol', label: 'Plan Vol' },
    { value: 'int_plan_vol', label: 'Int Plan Vol' },
    { value: 'ALC', label: 'ALC' },
    { value: 'Final', label:  'final'},
    { value: 'plan_net', label: 'Plan Net' },
    { value: 'cumul_plan_net', label: 'Cumul Plan Net' },
    { value: 'date_time', label: 'Updated DT' },
    { value: 'userid', label: 'User' },
    { value: 'comment', label: 'Comments' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 5000;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  minVanDate: any;
  maxVanDate: any;

  uploading: boolean = false;



  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  onInitVanningFrom: any;
  onInitVanningTo: any;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  flalLicenseKeySubscription$: Subscription;
  animateRows: boolean;
  groupUseEntireRow: boolean;
  spinnerMessage: any;
  modalComponent: string;
  modifiedRowData: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService,
    private readonly stateService: OSPStateService,
    public dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }

  headers = [
    'Van DT',
    'Part No',
    'Kanban',
    'CC',
    'QPC',
    'Comments',
    'Remarks',
  ];

  headersSameAsUI = [
    'Van DT',
    'Part No',
    'Part Description',
    'Kanban',
    'CC',
    'QPC',
    'SPC',
    'Updated DT',
    'User',
    'Comments',
  ];

  fileName: string = '';
  access1;

  setVanningDates(): void {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.flalService.getMaxDate(data).subscribe((success) => {
      if (!success.body.data.minVanDate[0].min) {
        this.spinner.hide();
        return;
      }

      this.vanningFrom = success.body.data.minVanDate[0].min;
      this.onInitVanningFrom = this.vanningFrom;

      this.spinner.hide();
    });
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    if (this.flalSubscription$) this.flalSubscription$.unsubscribe();
    if (this.flalLicenseKeySubscription$) this.flalLicenseKeySubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };
    this.rowData = [
      { production_date: "10/23/2024",
       vehicle_type: "ALL",
        Shift: "1", 
        pla_vol: 256,
        int_plan_vol:'',
        ALC:272,
        final:0,
        plan_net:7,
        cumul_plan_net:-242,
        plan_lo_dt:'',
        plan_lo_qty:null,
        children :[{
        plan_lo_dt:'10/11/2024',
        plan_lo_qty:249


      },{
        plan_lo_dt:'10/20/2024',
        plan_lo_qty:249
      }]},
        // { production_date: "10/14/2024",
        // vehicle_type: "ALL",
        //  Shift: "1", 
        //  pla_vol: 256,
        //  int_plan_vol:'',
        //  ALC:272,
        //  final:0,
        //  plan_net:7,
        //  cumul_plan_net:-242,
        //  plan_lo_dt:'10/12/2024',
        //  plan_lo_qty:240},
        //  { production_date: "10/14/2024",
        //  vehicle_type: "ALL",
        //   Shift: "1", 
        //   pla_vol: 256,
        //   int_plan_vol:'',
        //   ALC:272,
        //   final:0,
        //   plan_net:7,
        //   cumul_plan_net:-242,
        //   plan_lo_dt:'10/20/2024',
        //   plan_lo_qty:249},
          { production_date: "10/15/2024",
          vehicle_type: "ALL",
           Shift: "1", 
           pla_vol: 256,
           int_plan_vol:'',
           ALC:272,
           final:0,
           plan_net:7,
           cumul_plan_net:-242,
           plan_lo_dt:'10/11/2024',
           plan_lo_qty:249
          },
          //  { production_date: "10/15/2024",
          //  vehicle_type: "ALL",
          //   Shift: "1", 
          //   pla_vol: 256,
          //   int_plan_vol:'',
          //   ALC:272,
          //   final:0,
          //   plan_net:7,
          //   cumul_plan_net:-242,
          //   plan_lo_dt:'10/22/2024',
          //   plan_lo_qty:249},
            { production_date: "11/14/2024",
            vehicle_type: "ALL",
             Shift: "1", 
             pla_vol: 256,
             int_plan_vol:'',
             ALC:272,
             final:0,
             plan_net:7,
             cumul_plan_net:-242,
             plan_lo_dt:'10/11/2024',
             plan_lo_qty:249}
 





  ];
    this.icons = {
      groupExpanded:
        '<img src="/assets/images/cellgrid_minus.png" style="height: 18px; width: 18px;padding-right: 2px"/>',
      groupContracted:
        '<img src="/assets/images/cellgrid_plus.png" style="height: 14px; width: 14px;padding-right: 2px"/>',
    };
    
    this.gridOptions.defaultColDef.floatingFilter = true;
    this. detailCellRendererParams = {
      detailGridOptions: {
        
        columnDefs: [
          { headerName:'PLan LO DT',
            width:100,
            field: "plan_lo_dt",  },
          { headerName:'PLan LO QTY',
            field: "plan_lo_qty" },
        ],
        defaultColDef: {
          flex: 1,
        },
        // groupDefaultExpanded: 1,
        masterDetail: true,
        detailRowHeight: 25,
        detailRowAutoHeight: true,
        getRowHeight: function(params) { if (params.node && params.node.detail) { 
          // return the height you want for detail rows 
          return 500; // adjust this value as needed
         } 
         // return the height you want for regular rows 
         
         return 50; // adjust this value as needed
         },
         getRowClass: function(params) {
          // Check if the row has children
          console.log("params--",params.node);
          console.log("params1",params.node.parent.group , !params.node.parent.childrenAfterFilter.length);
          
          if (!params.node.parent.group) {
              return 'no-expand';
          }
          return '';
      }, 
      
      },
      
      getDetailRowData: (params) => {
        params.successCallback(params.data.children);
      },
    } as IDetailCellRendererParams;

    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 40,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
        {
        headerName: 'Production date',
        field: 'production_date',
        sortable: true,
        width: 130,
        floatingFilter: true,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Module Type',
        field: 'vehicle_type',
        sortable: true,
        width: 130,
        floatingFilter: true,

      },
      {
        headerName: 'Shift',
        field: 'Shift',
        sortable: true,
        width: 100,
        floatingFilter: true,

      },
      {
        headerName: 'Plan VOL',
        field: 'pla_vol',
        sortable: true,
        width: 100,
        floatingFilter: true,

      },
      {
        headerName: 'Int Plan VOL',
        field: 'int_plan_vol',
        sortable: true,
        width: 130,
        floatingFilter: true,
        cellRendererFramework: ProductionProgressEditorComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.int_plan_vol;
          } else {
            const value = params.data.int_plan_vol?.value ?? params.data.int_plan_vol;

            return value;
          }
        },
        // filterParams: {
        //   numberParser: (text) => {
        //     return text === null ? null : parseFloat(text.replace(',', '.'));
        //   },
        // },

        filter: 'agNumberColumnFilter',
        // floatingFilterComponentParams: { suppressFilterButton: false },





      },
      {
        headerName: 'ALC',
        field: 'ALC',
        sortable: true,
        width: 100,
        floatingFilter: true,

      },
      {
        headerName: 'Final',
        field: 'final',
        sortable: true,
        width: 100,
        floatingFilter: true,
        cellRendererFramework: ProductionProgressEditorComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.final;
          } else {
            const value = params.data.tapQuantity?.value ?? params.data.final;

            return value;
          }
        },
        // filterParams: {
        //   numberParser: (text) => {
        //     return text === null ? null : parseFloat(text.replace(',', '.'));
        //   },
        // },

        filter: 'agNumberColumnFilter',
        // floatingFilterComponentParams: { suppressFilterButton: false },



      },
      {
        headerName: 'Plan Net',
        field: 'plan_net',
        sortable: true,
        width: 130,
        floatingFilter: true,

      },
      {
        headerName: 'Cumul Plan Net',
        field: 'cumul_plan_net',
        sortable: true,
        width: 130,
        floatingFilter: true,
        cellRendererFramework: ProductionProgressEditorComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.cumul_plan_net;
          } else {
            const value = params.data.cumul_plan_net?.value ?? params.data.cumul_plan_net;

            return value;
          }
        },
        // filterParams: {
        //   numberParser: (text) => {
        //     return text === null ? null : parseFloat(text.replace(',', '.'));
        //   },
        // },

        filter: 'agNumberColumnFilter',
        // floatingFilterComponentParams: { suppressFilterButton: false },

        



      },
      
      // {
      //   headerName: 'Plan LO Date',
      //   field: 'plan_lo_dt',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      // {
      //   headerName: 'Plan LO QTY',
      //   field: 'plan_lo_qty',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'updated_userid',
        sortable: true,
        width: 180,
        floatingFilter: true,
        tooltipField: 'updated_userid',

      },
      {
        headerName: 'Updated DT',
        field: 'updated_date_time',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        sortable: true,
        width: 180,
        floatingFilter: true,
        tooltipField: 'updated_date_time',
      },



],    // Define the raw data with a custom structure



    this.rowData = [
      { production_date: "10/23/2024",
       vehicle_type: "ALL",
        Shift: "1", 
        pla_vol: 256,
        int_plan_vol:'',
        ALC:272,
        final:0,
        plan_net:7,
        cumul_plan_net:-242,
        plan_lo_dt:'',
        plan_lo_qty:null,
        children :[{
        plan_lo_dt:'10/11/2024',
        plan_lo_qty:249


      },{
        plan_lo_dt:'10/20/2024',
        plan_lo_qty:249
      }]},
        // { production_date: "10/14/2024",
        // vehicle_type: "ALL",
        //  Shift: "1", 
        //  pla_vol: 256,
        //  int_plan_vol:'',
        //  ALC:272,
        //  final:0,
        //  plan_net:7,
        //  cumul_plan_net:-242,
        //  plan_lo_dt:'10/12/2024',
        //  plan_lo_qty:240},
        //  { production_date: "10/14/2024",
        //  vehicle_type: "ALL",
        //   Shift: "1", 
        //   pla_vol: 256,
        //   int_plan_vol:'',
        //   ALC:272,
        //   final:0,
        //   plan_net:7,
        //   cumul_plan_net:-242,
        //   plan_lo_dt:'10/20/2024',
        //   plan_lo_qty:249},
          { production_date: "10/15/2024",
          vehicle_type: "ALL",
           Shift: "1", 
           pla_vol: 256,
           int_plan_vol:'',
           ALC:272,
           final:0,
           plan_net:7,
           cumul_plan_net:-242,
           plan_lo_dt:'10/11/2024',
           plan_lo_qty:249
          },
          //  { production_date: "10/15/2024",
          //  vehicle_type: "ALL",
          //   Shift: "1", 
          //   pla_vol: 256,
          //   int_plan_vol:'',
          //   ALC:272,
          //   final:0,
          //   plan_net:7,
          //   cumul_plan_net:-242,
          //   plan_lo_dt:'10/22/2024',
          //   plan_lo_qty:249},
            { production_date: "11/14/2024",
            vehicle_type: "ALL",
             Shift: "1", 
             pla_vol: 256,
             int_plan_vol:'',
             ALC:272,
             final:0,
             plan_net:7,
             cumul_plan_net:-242,
             plan_lo_dt:'10/11/2024',
             plan_lo_qty:249}
 





  ];

    console.log(this.rowData)
        this.rowSelection = 'multiple';
        this.groupUseEntireRow= true,
    // this.pagination = true;
    // paginationPageSize = 10;
    this.groupDefaultExpanded = 0; // Expand all groups by default
    // this.groupDisplayType = 'groupRows';
    // this.animateRows = true;
    this.editType = 'fullRow';
    this.paginationPageSize = this.rowData.length;

    
    // this.treeData = 'true'
  
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    //   params.api.forEachNode((node) => {
    //     if (!node.childrenAfterGroup.length) {
    //         node.setExpanded(true);
    //     }
    // });


    };

  }
  

    // this.headerHeight = 45;

    // this.rowSelection = 'multiple';
    // this.editType = 'fullRow';
    // this.setPageSizeToAll(this.defaultPageSize);

    // this.flalLicenseKeySubscription$ = this.stateService
    //   .getLicenseKeySetValue()
    //   .subscribe((licenseKeyHasBeenSet: boolean) => {
    //     // console.log(licenseKeyHasBeenSet);
    //     if (!licenseKeyHasBeenSet) return;
    //   });

    // this.flalSubscription$ = this.stateService.getNamc().subscribe((observable) => {
    //   this.initializeData();
    // });






  initializeData() {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.spinner.show();
    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    const faPermission = checkAccess();
    this.access1 = faPermission;

    this.loadDrop();

    // Setting van dates after getting batch status
    // this.getBatchStatus('');
    // this.setVanningDates();
    // eastern time api
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }

  //freezing logic
  batchCheck = true;
  freezingMesssage = '';

  getBatchStatus(callback) {
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const count = res.body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res.body.data.runningBatches}  batches are running, so edit/upload is not allowed for some time.`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
          this.spinner.hide();

          if (callback) {
            callback();
          }
        }
      },
    });
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };


  }


  onSearch(offset, removeMessage) {
    if (removeMessage) {
      this.removeMessage();
    }

    if (offset === 0) {
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.vanningFrom,
        vanningTo: this.vanningTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }


    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectVanFrom;

      window.scroll(0, 0);

      this.spinner.hide();

      return;
    }

    this.osPartsGrid.gridOptions.api.showLoadingOverlay();

    this.loading = true;

    const data = {
      offset: offset,
      limit: this.apiDefaultLimit,

      kanban: this.selectedKanban.map((e) => e.kanban),
      item_id: this.selectedPartNo.map((e) => e.item_id),
      dest_code: this.selectedDestCode.map((e) => e.dest_code),
      cont_code: this.selectedContainer.map((e) => e.cont_code),
      customer_supp: this.selectedSupplier.map((e) => e.customer_supp),
      specialist: this.selectedSpecialist.map((e) => e.EMPLOYEE),

      vanning_to: this.vanningTo,
      vanning_from: this.vanningFrom,

      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      currenteasternDate: this.easternDate,
    };

    //if all selected
    if (data.kanban.includes('ALL')) {
      data.kanban = [];
    }

    if (data.item_id.includes('ALL')) {
      data.item_id = [];
    }
    if (data.dest_code.includes('ALL')) {
      data.dest_code = [];
    }
    if (data.cont_code.includes('ALL')) {
      data.cont_code = [];
    }
    if (data.customer_supp.includes('ALL')) {
      data.customer_supp = [];
    }
    if (data.specialist.includes('ALL')) {
      data.specialist = [];
    }

    this.flalService.getFAGrid(data).subscribe({
      error: this.errorCallback,
      next: (response) => {
        const { data, rowCount } = response.body.data;
        if (!offset && data.length < 1) {
          this.spinner.hide();
          this.warningMessage = warningMessage.noDataAvailble;

          return;
        }

        this.rowData.push(...data);

        this.dataCount = Math.round(parseFloat(rowCount));

        const records = this.dataCount - (this.offset + 1) * this.apiDefaultLimit;

        if (records > 0) {
          this.offset++;
          this.onSearch(this.offset * this.apiDefaultLimit, false);
          return;
        } else {
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

          this.spinner.hide();
          this.loading = false;
        }

        this.setPageSizeToAll();
      },
    });
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  checkIfAnySelected() {
    return true

    // if (
    //   this.selectedDock.length > 0

    // ) {
    //   return true;
    // }

    // return false;
  }
  hideSpinner() {
    this.spinner.hide();
  }
  onDockChange(): void {
    if (this.selectedDock.length >= 1) {
      this.selectedDock = {
        dock: [this.selectedDock.dock],
      };
    }
  }
  shift_column;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.shift_column = hideItems;
    if (!hideItems.length) this.shift_column = [{}];
  }
  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);


    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  ReviewChanges() {
    this.removeMessage();

    this.modifiedRowData = this.getModifiedRowData();
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';

        // validation


        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  reviewData() {
    this.openReviewDialog(this.modifiedRowData);
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ProgressEditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          var updatedData = [];
          var pieupdatedData = [];
          this.successMessage = 'Record has been updated successfully';
        }
      });

    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    dialogConfig.data='ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        // this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }
  // saveShowHide() {
  //   let shift_column = this.shift_column;
  //   const data = {
  //     business_entity: this.namcValue,
  //     business_entity_name: this.namcName,
  //     workid: this.user,
  //     user_role: this.userRole,
  //   };

  //   if (shift_column) {
  //     this.lookupService.updateUserShowHideColumns(shift_column).subscribe({
  //       error: this.errorCallback,
  //       next: (res) => {
  //         this.spinner.hide();
  //       },
  //     });
  //   } else {
  //     this.spinner.hide();
  //   }
  // }



  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  getModifiedRowData(): any {
    console.log(this.rowData)
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox );
    return modifiedRowData;
  }

  getSelectedRows(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];

    this.kanbanSearchValue = null;

    this.vanningFrom = this.onInitVanningFrom;
    this.vanningTo = this.onInitVanningTo;

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }











  cancelEdit(): void {
    // this.openConfirmationDialog();
  }














  convertNaN(value) {
    value = Math.round(parseFloat(value?.trim()));
    return isNaN(value) ? '' : value;
  }





  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    //this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  // showSpinner(message?: string) {
  //   if (!message) {
  //     this.spinnerMessage = this.spinnerMessage;
  //   } else {
  //     this.spinnerMessage = message;
  //   }
  // }
  fa_columns
  saveShowHide() {
    this.spinner.show();
    let fa_columns = this.fa_columns;
    if (fa_columns) {
      this.lookupService.updateUserShowHideColumns({ fa_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
  exportAsXLSX(): void {
    this.groupDefaultExpanded=-1
    this.spinner.show();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }


    setTimeout(async () => {
      if (data.length > 0) {
        const exportData: any = [];

        data.forEach((e) => {
          const obj = {
            production_date: e.production_date,
            vehicle_type: e.vehicle_type,
            shift: e.shift,
            plan_vol: e.plan_vol,
            int_plan_vol: e.int_plan_vol,
            alc: e.alc,
            final: e.final,
            plan_net: e.plan_net,
            cumul_plan_net: e.cumul_plan_net,
            updated_userid: e.updated_userid,
            updated_date_time: e.updated_date_time,
          };
          exportData.push(obj);
        });
        const headers = [
          'Production date',
          'Module Type',
          'Plan Vol',
          'Int Plan Vol',
          'ALC',
          'Final',
          'Plan Net',
          'Cumul Plan Net',
          'User',
          'Updated DT',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'ProductionProgress' + timestamp, headers);
        }
      } else {
        this.warningMessage = warningMessage.noRowsSelected;
      }

      this.hideSpinner();
    }, 1000);
  }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }



  
}


