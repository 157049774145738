<div *ngIf="showCanceledPopUP">
<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
       Are you sure to cancel this air order record
      </h3>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
      
      </button>
    </div>
  </div>
  
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button [mat-dialog-close]="'save'">
        save
      </button>
    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
  </div>

  <div *ngIf="!showCanceledPopUP">
    <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
        <div class="col-sm-11 movable">
          <h3
            class="head"
            cdkDrag
            cdkDragHandle
            cdkDragRootElement=".cdk-overlay-pane"
          >
           Are you sure to activate this air order record
          </h3>
        </div>
        <div class="col-sm-1">
          <button
            type="button"
            class="btn btn-dark btn-circle btn-xl"
            [mat-dialog-close]="true"
            data-dismiss="modal"
            aria-label="Close"
          >
            
          </button>
        </div>
      </div>
      
      <div class="text-right" style="margin-top: 20px">
        <button class="review_btn" mat-button mat-dialog-close="save">
          save
        </button>
        <button class="cancel_btn" mat-button mat-dialog-close="cancel">
          Cancel
        </button>
      </div>
      </div>
  
  