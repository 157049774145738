import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private orderDataSubject = new BehaviorSubject<any>(null);
  orderData$ = this.orderDataSubject.asObservable();

  setOrderData(data: any) {
    this.orderDataSubject.next(data);
  }

  clearOrderData() {
    this.orderDataSubject.next(null);
  }
}
