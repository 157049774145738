<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}

      <button
        *ngIf="warningMessage"
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <h2 class="heading">AIR FREIGHT ORDER</h2>
      </div>
      <div class="col-9">
        <button type="button" class="master-primary-btn" (click)="addNew()">
          + ADD NEW
        </button>

        <!-- <button *ngIf="addGetResponse.length>0" type="button" class="master-primary-btn" (click)="viewCart()">
            View Cart
          </button> -->
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DEST CODE</label>
                <p-multiSelect
                  [(ngModel)]="selectedDestCode"
                  [showToggleAll]="false"
                  (onChange)="onDestCodeChange()"
                  [options]="destCodeList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="dest_code"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT FROM </label>
                <input
                  [(ngModel)]="ordDate"
                  [max]="ordDateTo"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                  style="width:100px"
                />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT TO</label>
                <input
                [(ngModel)]="ordDateTo"
                [min]="ordDate"
                class="form-control-sm"
                type="date"
                autoWidth="false"
                style="width:100px"
              />
            </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">DOCK</label>
                <p-multiSelect
                  [showToggleAll]="false"
                  [(ngModel)]="selectedDock"
                  [options]="dockList"
                  (onChange)="dockChange()"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="dock"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">AIR CASE CODE</label>
                <p-multiSelect
                  [showToggleAll]="false"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="dock"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PART NO</label>
                <p-multiSelect
                  [(ngModel)]="selectedPartNo"
                  (onChange)="onPartNoChange($event)"
                  [showToggleAll]="false"
                  [options]="partNoList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="part_no"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">KANBAN</label>
                <p-multiSelect
                  [(ngModel)]="selectedKanban"
                  [showToggleAll]="false"
                  (onChange)="onKanbanChange()"
                  [options]="kanbanList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="kanban"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">STATUS</label>
                <p-multiSelect
                  [(ngModel)]="selectedStatus"
                  [showToggleAll]="false"
                  (onChange)="statusChange()"
                  [options]="statusList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="air_order_status"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                >
                </p-multiSelect>
              </div>
            </div>


            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                  (click)="onResetDropDown()"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <span class="edit_icon">
          <img
            class="img-tool"
            src="./assets/images/download.svg"
            alt="download icon"
            title="Download"
          />
        </span>
      </div>

      <div class="col-sm-2">
        <div class="records">
          <div class="drpchildctrl">
            <p-multiSelect
              #dd
              class="page-drpdwn-label showhide"
              [(ngModel)]="selectedShowHideList"
              [options]="showHideList"
              (onChange)="onShowHideChange($event)"
              optionLabel="label"
              [displaySelectedLabel]="false"
              [defaultLabel]="'SHOW/HIDE COLUMN'"
              [showTransitionOptions]="'0ms'"
              [hideTransitionOptions]="'0ms'"
              autoWidth="true"
              [style]="{ width: '100%', background: '#F5F5F5' }"
              [resetFilterOnHide]="true"
            >
              <p-footer>
                <div class="flex justify-content-between p-1">
                  <button
                    type="button"
                    (click)="saveShowHide(); dd.hide()"
                    class="save_btn"
                  >
                    Save
                  </button>
                </div>
              </p-footer>
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <ag-grid-angular
      #osPartsGrid
      style="width: 100%; top: 1%; margin-bottom: 5%"
      class="ag-theme-balham"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
      [groupRowsSticky]="groupRowsSticky"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [editType]="editType"
      [showOpenedGroup]="showOpenedGroup"
      [groupSelectsChildren]="groupSelectsChildren"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [suppressAggFuncInHeader]="suppressAggFuncInHeader"
      [groupDisplayType]="groupDisplayType"
    >
    </ag-grid-angular>
  </div>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>
